import { category } from '../index';
import { ComponentService, IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none">
<path d="M13.75 12.5C14.413 12.5 15.0489 12.2366 15.5178 11.7678C15.9866 11.2989 16.25 10.663 16.25 10C16.25 9.33696 15.9866 8.70107 15.5178 8.23223C15.0489 7.76339 14.413 7.5 13.75 7.5C13.087 7.5 12.4511 7.76339 11.9822 8.23223C11.5134 8.70107 11.25 9.33696 11.25 10C11.25 10.663 11.5134 11.2989 11.9822 11.7678C12.4511 12.2366 13.087 12.5 13.75 12.5Z" fill="currentColor"/>
<path d="M1.25 10C1.25 8.67392 1.77678 7.40215 2.71447 6.46447C3.65215 5.52678 4.92392 5 6.25 5H13.75C15.0761 5 16.3479 5.52678 17.2855 6.46447C18.2232 7.40215 18.75 8.67392 18.75 10C18.75 11.3261 18.2232 12.5979 17.2855 13.5355C16.3479 14.4732 15.0761 15 13.75 15H6.25C4.92392 15 3.65215 14.4732 2.71447 13.5355C1.77678 12.5979 1.25 11.3261 1.25 10ZM6.25 6.25C5.25544 6.25 4.30161 6.64509 3.59835 7.34835C2.89509 8.05161 2.5 9.00544 2.5 10C2.5 10.9946 2.89509 11.9484 3.59835 12.6517C4.30161 13.3549 5.25544 13.75 6.25 13.75H13.75C14.7446 13.75 15.6984 13.3549 16.4017 12.6517C17.1049 11.9484 17.5 10.9946 17.5 10C17.5 9.00544 17.1049 8.05161 16.4017 7.34835C15.6984 6.64509 14.7446 6.25 13.75 6.25H6.25Z" fill="currentColor"/>
</svg>
`

export const PlToggleConfig: IComponentsBundleItem = {
  id: 'toggle',
  name: 'Toggle',
  tag: 'pl-toggle',
  container: false,
  category: category.forms,
  svgIcon: svg,
  defaults: {
    attributes: {
      label: 'Toggle label',
    },
  },
  events: ['change'],
  configurators: {
    enable: [
      {
        id: 'text',
        config: {
          attribute: 'label',
          label: 'Label',
          placeholder: 'Toggle label',
        },
      },
      {
        id: 'boolean',
        config: {
          attribute: 'checked',
          label: 'Checked',
        },
      },
      {
        id: 'fontSize',
      },
      {
        id: 'visibility',
      },
      {
        id: 'actions',
        config: {
          event: 'change',
        },
      },
    ],
  },
  variables: [
    { name: 'label', bind: 'attribute', bindName: 'label' },
    { name: 'checked', bind: 'attribute', bindName: 'checked' },
  ],
  afterCreate: (e: HTMLElement, c: ComponentService) => {
    e.addEventListener('change', (e: Event) => {
      const v = (e.target as HTMLInputElement).checked;
      c.SetVariable('checked', String(v));
    });
  },
};
