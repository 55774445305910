import { category } from '../index';
import { IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <path fill="currentColor" d="M12 3H4a1 1 0 0 0-1 1v2.5H2V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2.5h-1V4a1 1 0 0 0-1-1M2 9.5h1V12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9.5h1V12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm-1.5-2a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"/>
</svg>
`

export const PlDividerConfig: IComponentsBundleItem = {
  id: 'divider',
  name: 'Divider',
  tag: 'pl-divider',
  container: false,
  category: category.primitives,
  svgIcon: svg,
  defaults: {},
  events: [],
  configurators: {
    enable: [
      {
        id: 'visibility',
      },
    ],
  },
};
