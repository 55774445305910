import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';

@defineCustomElement('pl-toggle')
export class PlToggle extends HTMLElement {
  private connected = false;
  private readonly input: HTMLInputElement;
  private readonly label: HTMLLabelElement;
  private readonly toggle: HTMLSpanElement;
  private readonly toggleKnob: HTMLSpanElement;

  constructor() {
    super();
    this.input = document.createElement('input');
    this.input.type = 'checkbox';
    this.input.addEventListener('change', this.inputChange);
    this.input.id = crypto.randomUUID();
    this.label = document.createElement('label');
    this.label.htmlFor = this.input.id;
    this.label.addEventListener('click', this.toggleClick);
    this.toggle = document.createElement('span');
    this.toggle.addEventListener('click', this.toggleClick);
    this.toggleKnob = document.createElement('span');
  }

  toggleClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.input.checked = !this.input.checked;
    this.updateChecked();
  };

  inputChange = () => {
    this.updateChecked();
  };

  @attribute('label') setLabel(v: string | null) {
    this.label.innerHTML = v ?? '';
  }

  @attribute('checked') setChecked(v: string | null) {
    this.input.checked = v === 'true';
    this.updateChecked();
  }

  connectedCallback() {
    if (!this.connected) {
      this.input.checked = this.getAttribute('checked') === 'true';
      this.append(this.input);
      this.toggle.append(this.toggleKnob);
      this.append(this.toggle);
      const _l = this.getAttribute('label');
      this.label.innerHTML = _l ? _l : '';
      this.append(this.label);
      this.updateChecked();
      this.connected = true;
    }
  }

  updateChecked() {
    this.toggle.style.justifyContent = this.input.checked
      ? 'flex-end'
      : 'flex-start';
    if (this.input.checked) {
      this.input.setAttribute('checked', '');
    } else {
      this.input.removeAttribute('checked');
    }
    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        cancelable: true,
      }),
    );
  }

  // noinspection JSUnusedGlobalSymbols
  disconnectedCallback() {
    this.input.removeEventListener('change', this.inputChange);
    this.toggle.removeEventListener('click', this.toggleClick);
  }
}
