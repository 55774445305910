import { category } from '../index';
import { IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M16.95 8.46761C17.0421 8.62345 17.0421 8.83125 16.95 8.9871L15.3103 11.6365C15.2182 11.7923 15.0339 11.8962 14.8313 11.8962C14.6286 11.8962 14.4443 11.7923 14.3522 11.6365L12.7125 8.9871C12.6204 8.83125 12.6204 8.62345 12.7125 8.46761C12.8046 8.31176 12.9889 8.20786 13.1915 8.20786H16.4526C16.6552 8.20786 16.8395 8.31176 16.95 8.46761ZM9.85679 13.8703C9.85679 14.1647 9.7094 14.3898 9.51687 14.3898H1.81383C1.6213 14.3898 1.47391 14.1647 1.47391 13.8703V5.55847C1.47391 5.26409 1.6213 5.03898 1.81383 5.03898H9.51687C9.7094 5.03898 9.85679 5.26409 9.85679 5.55847V13.8703ZM2.09185 4C0.929855 4 0 4.16624 0 5.55847V13.8703C0 15.4288 0.929855 15.4288 2.09185 15.4288H17.8981C19.0601 15.4288 19.9899 15.2625 19.9899 13.8703V5.64159C20.1146 4.41559 19.0601 4 17.8981 4H2.09185Z"/>
</svg>
`

export const PlSelectConfig: IComponentsBundleItem = {
  id: 'select',
  name: 'Select',
  tag: 'pl-select',
  container: false,
  category: category.forms,
  svgIcon: svg,
  defaults: {
    attributes: {
      gap: 'xs',
      label: 'Select label',
    },
    style: {
      'margin-bottom': 'var(--pl-spacing-md)',
    },
  },
  events: ['change'],
  configurators: {
    enable: [
      {
        id: 'text:label',
        config: {
          attribute: 'label',
          label: 'Label',
          placeholder: 'Select box label',
        },
      },
      {
        id: 'fontSize',
      },
      {
        id: 'bind',
        config: {
          types: ['array'],
        },
      },
      {
        id: 'visibility',
      },
    ],
  },
};
