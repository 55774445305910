import { IPlTreeItem } from './pl-tree';
import { PlBox } from '../box/pl-box';
import { attribute } from '../_decorators/attribute';
import { defineCustomElement } from '../_decorators/element';

@defineCustomElement('pl-tree-branch')
export class PlTreeBranch extends HTMLElement {
  private connected = false;
  private readonly row: PlBox;
  private readonly rowLink: HTMLAnchorElement;
  private readonly rowChevron: HTMLElement;

  constructor() {
    super();
    this.rowChevron = document.createElement('pl-tree-chevron');
    this.rowLink = document.createElement('a');
    this.row = new PlBox();
    this.row.setAttribute('align', 'center');
    this.row.addEventListener('click', this.clickOnRow);
  }

  @attribute('open') setLabel(v: string | null) {
    const open = v === 'true';
    if (this.rowChevron && v) {
      this.rowChevron.setAttribute('open', v);
    }
    this.querySelectorAll('pl-tree-branch').forEach((br: Element) => {
      (br as HTMLElement).style.display = open ? 'flex' : 'none';
    });
  }

  private clickOnLink = (_: MouseEvent) => {
    return false;
  };

  private clickOnRow = (e: MouseEvent) => {
    const v = this.getAttribute('open');
    this.setAttribute('open', v === 'true' ? 'false' : 'true');
    e.preventDefault();
    return false;
  };

  public Build(data: IPlTreeItem) {
    this.innerHTML = '';
    this.setAttribute('open', data.open ? 'true' : 'false');
    if (data.tree && Array.isArray(data.tree) && data.tree.length > 0) {
      this.rowChevron.setAttribute('open', data.open ? 'true' : 'false');
      this.rowLink.href = '#';
      this.rowLink.innerHTML = data.label;
      this.rowLink.addEventListener('click', this.clickOnLink);
      this.row.append(this.rowChevron);
      this.row.append(this.rowLink);
      this.append(this.row);
      data.tree.forEach((item) => {
        const br = new PlTreeBranch();
        br.style.display = data.open ? 'flex' : 'none';
        br.Build(item);
        this.append(br);
      });
    } else {
      const es = document.createElement('span');
      es.innerHTML = '&nbsp;';
      this.row.append(es);
      const sl = document.createElement('label');
      sl.innerHTML = data.label;
      this.row.append(sl);
      this.append(this.row);
    }
  }

  connectedCallback() {
    if (!this.connected) {
      this.connected = true;
    }
  }

  disconnectedCallback() {
    this.row.removeEventListener('click', this.clickOnRow);
    if (this.rowLink) {
      this.rowLink.removeEventListener('click', this.clickOnLink);
    }
  }
}
