export const de = document.documentElement;
export const dc = (n: string, a:{[index:string]:string} = {}, s:{[index:string]:string} = {}):HTMLElement => {
  const e = document.createElement(n)
  if(Object.keys(a).length>0) {
    Object.keys(a).forEach(ak => e.setAttribute(ak,a[ak]))
  }
  if(Object.keys(s).length>0) {
    Object.keys(s).forEach(sk => e.style.setProperty(sk,s[sk]))
  }
  return e
};
