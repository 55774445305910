import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';

@defineCustomElement('pl-checkbox')
export class PlCheckbox extends HTMLElement {
  private connected = false;
  private readonly input: HTMLInputElement;
  private readonly label: HTMLLabelElement;

  public get checked(): boolean {
    return this.input.checked;
  }

  public set checked(v: boolean) {
    this.setAttribute('checked', v ? 'true' : 'false');
  }

  private syncInputCheckedToAttribute = () => {
    this.setAttribute('checked', this.input.checked ? 'true' : 'false');
  };

  constructor() {
    super();
    this.input = document.createElement('input');
    this.input.addEventListener('change', this.syncInputCheckedToAttribute);
    this.input.type = 'checkbox';
    this.input.id = crypto.randomUUID();
    this.label = document.createElement('label');
    this.label.htmlFor = this.input.id;
  }

  @attribute('label')
  public setLabel(v: string | null) {
    this.label.innerHTML = v ?? '';
  }

  @attribute('checked')
  public setChecked(v: string | boolean | null) {
    if (typeof v === 'string') this.input.checked = v === 'true';
    if (typeof v === 'boolean') this.input.checked = v;
  }

  connectedCallback() {
    if (!this.connected) {
      this.append(this.input);
      this.append(this.label);
      this.connected = true;
    }
  }

  disconnectedCallback() {
    this.input.removeEventListener('change', this.syncInputCheckedToAttribute);
  }
}
