import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';

@defineCustomElement('pl-link')
export class PlLink extends HTMLElement {
  private connected = false;

  private readonly icon: HTMLElement;

  constructor() {
    super();
    this.icon = document.createElement('i');
  }

  @attribute('icon') setIcon() {
    this.renderIcon();
  }

  renderIcon() {
    const a = this.getAttribute('icon');
    const i = this.querySelector('i');
    // icon is not set
    if (!a) {
      // if <i> child exist - remove
      if (i) {
        this.removeChild(this.icon);
      }
    }
    // if icon and <i> child in place - update
    if (i && a) {
      i.className = a;
      return;
    }
    // if icon is set and <i> is missing - append
    if (!i && a) {
      this.icon.className = a;
      this.prepend(this.icon);
    }
  }

  connectedCallback() {
    if (!this.connected) {
      this.renderIcon();
    }
  }
}
