import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';

@defineCustomElement('pl-select')
export class PlSelect extends HTMLElement {
  private ___observer: MutationObserver;
  private ___value: string = '';
  private connected = false;
  private readonly select: HTMLSelectElement;
  private readonly label: HTMLLabelElement;

  constructor() {
    super();
    this.___observer = new MutationObserver((list) =>
      this.onserveCallback(list),
    );
    this.select = document.createElement('select');
    this.select.id = crypto.randomUUID();
    this.label = document.createElement('label');
    this.label.htmlFor = this.select.id;
  }

  onserveCallback(mutationList: MutationRecord[]) {
    let refresh = false;
    for (let i = 0; i < mutationList.length; i++) {
      const mi = mutationList[i];
      if (mi.type === 'childList') {
        mi.addedNodes.forEach((n) => {
          if (n.parentElement === this) {
            refresh = true;
          }
        });
        mi.removedNodes.forEach((n) => {
          if (n.parentElement === this) {
            refresh = true;
          }
        });
      }
    }
    if (refresh) {
      this.handleOptions();
    }
  }

  @attribute('label') setLabel(v: string | null) {
    this.label.innerHTML = v ?? '';
  }

  @attribute('value') setValue(v: string | null) {
    this.___value = v ?? '';
    this.setSelectedOption();
  }

  setSelectedOption() {
    let si = -1;
    for (let i = 0; i < this.select.options.length; i++) {
      if (this.select.options[i].value === this.___value) {
        si = i;
      }
    }
    if (si >= 0) {
      this.select.selectedIndex = si;
    }
  }

  handleOptions() {
    this.select.innerHTML = '';
    this.querySelectorAll('pl-option').forEach((option) => {
      const newOption = document.createElement('option');
      const v = option.getAttribute('value');
      if (v) {
        newOption.setAttribute('value', v);
      }
      newOption.innerHTML = option.innerHTML;
      this.select.append(newOption);
    });
    this.setSelectedOption();
  }

  connectedCallback() {
    if (!this.connected) {
      // const options = this.innerHTML
      this.setValue(this.getAttribute('value'));
      this.setLabel(this.getAttribute('label'));
      this.append(this.label);
      this.append(this.select);
      this.handleOptions();
      this.connected = true;
      this.___observer.observe(this, {
        attributes: false,
        childList: true,
        subtree: true,
      });
    }
  }

  disconnectedCallback() {
    this.___observer.disconnect();
  }
}

