import {
  IQuery,
  IQueryExecuteFailure,
  IQueryExecuteSuccess,
  IQuerySavingFailure,
} from '../interfaces/query/IQuery';
import {
  publishEvent,
  pubVariableSet,
  QUERIES_LOADED,
  QUERY_CREATED,
  QUERY_DELETED,
  QUERY_EXECUTE_FAILURE,
  QUERY_EXECUTE_SUCCESS,
  QUERY_SAVED,
  QUERY_SAVING,
  QUERY_UPDATED,
} from '../events';
import { ApiService } from './ApiService';
import { ShowAlertActionHandler } from '../actions/handlers/show-alert';
import { ExpressionLayer } from './ExpressionLayer';
import { IQueryProcessedResponse } from '../interfaces/query/IQueryProcessedResponse';

export class QueryService {
  private static initialized = false;
  private static services: QueryService[] = [];
  private variables: { [index: string]: any };

  private constructor(public query: IQuery) {
    this.variables = {
      id: query.id,
      kind: query.kind,
      isLoading: false,
      data: {},
      rawData: {},
    };
    this.pubVarSet();
  }

  private pubVarSet() {
    pubVariableSet({
      level: 'query',
      screenId: null,
      componentId: null,
      name: 'queries.' + this.query.name,
      value: '',
    });
  }

  /*
   * STATIC
   */

  public static IsInitialized() {
    return QueryService.initialized;
  }

  public static Init(queries: IQuery[]) {
    QueryService.services = queries.map((q) => new QueryService(q));
    QueryService.initialized = true;
  }

  public static GetAll(): QueryService[] {
    if (!QueryService.services) {
      return [];
    }
    return QueryService.services;
  }

  public static Get(id: string): QueryService | undefined {
    return QueryService.services.find((s) => s.query.id == id);
  }

  public static GetVariables(): any {
    const res: { [index: string]: any } = {};
    this.GetAll().forEach((qs) => (res[qs.query.name] = qs.variables));
    return res;
  }

  /*
   * Instance methods
   */

  public setName(name: string) {
    this.query.name = name;
    publishEvent<IQuery>(QUERY_UPDATED, this.query);
  }

  public setURL(method: string, url: string) {
    this.query.options.method = method;
    this.query.options.url = url;
    publishEvent<IQuery>(QUERY_UPDATED, this.query);
  }

  public setBody(body_toggle: boolean, body: string[][], jsonBody: string) {
    this.query.options.body_toggle = body_toggle;
    this.query.options.body = body;
    this.query.options.json_body = jsonBody;
    publishEvent<IQuery>(QUERY_UPDATED, this.query);
  }

  public setHeaders(headers: string[][]) {
    this.query.options.headers = headers;
    publishEvent<IQuery>(QUERY_UPDATED, this.query);
  }

  public setUrlParams(url_params: string[][]) {
    this.query.options.url_params = url_params;
    publishEvent<IQuery>(QUERY_UPDATED, this.query);
  }

  public Run(variables: any) {
    if (!this.query || !this.query.options || !this.query.options.url) {
      return;
    }
    const url = variables
      ? ExpressionLayer.Eval(this.query.options.url, variables)
      : this.query.options.url;
    this.variables.isLoading = true;
    delete this.variables.data;
    delete this.variables.rawData;
    this.pubVarSet();
    fetch(url, {
      method: this.query.options.method,
    })
      .then((r) => {
        return new Promise<IQueryProcessedResponse>((resolve, reject) => {
          let contentType = 'application/json';
          const _ct = r.headers.get('content-type') ?? '';
          if (_ct.split(';').length > 0) {
            contentType = _ct.toLowerCase().split(';')[0];
          }
          const res: IQueryProcessedResponse = {
            contentType,
          };
          if (contentType === 'application/json') {
            r.json()
              .then((json) => {
                res.json = json;
                resolve(res);
              })
              .catch((e) => {
                res.error = e;
                reject(res);
              });
          } else {
            r.text()
              .then((text) => {
                res.text = text;
                resolve(res);
              })
              .catch((e) => {
                res.error = e;
                reject(res);
              });
          }
        });
      })
      .then((result: IQueryProcessedResponse) => {
        let rawData = null;
        if (result.json) {
          rawData = { ...result.json };
        } else if (result.text) {
          rawData = result.text;
        }
        let data = rawData;
        if (this.query.options.enableTransformation) {
          try {
            variables.data = data;
            data = new Function(
              `with(this) {${this.query.options.transformation}}`,
            ).call(variables);
          } catch (error: any) {
            data = { error: error.toString() };
          }
        }
        this.variables = {
          id: this.query.id,
          kind: this.query.kind,
          isLoading: false,
          data,
          rawData,
        };
        this.pubVarSet();
        publishEvent<IQueryExecuteSuccess>(QUERY_EXECUTE_SUCCESS, {
          result: data,
          query: this.query,
        });
        if (this.query.options && this.query.options.showSuccessNotification) {
          if (
            this.query.options.successMessage &&
            this.query.options.notificationDuration
          ) {
            const duration = Number(this.query.options.notificationDuration);
            ShowAlertActionHandler(undefined, undefined, undefined, {
              title: 'Query success',
              content: this.query.options.successMessage,
              duration,
              position: 'top',
            });
          }
        }
      })
      .catch((error) => {
        this.variables = {
          id: this.query.id,
          kind: this.query.kind,
          isLoading: false,
          data: {},
          rawData: {},
          error,
        };
        this.pubVarSet();
        publishEvent<IQueryExecuteFailure>(QUERY_EXECUTE_FAILURE, {
          error,
          query: this.query,
        });
      });
  }

  public static ReloadQueries(appId: string): Promise<void> {
    return ApiService.GetQueries(appId).then((queries) => {
      QueryService.Init(queries.data.data_queries);
      publishEvent(QUERIES_LOADED);
    });
  }

  public static CreateQuery(query: IQuery): Promise<IQuery> {
    publishEvent<IQuery>(QUERY_SAVING, query);
    return new Promise<IQuery>((resolve, reject) => {
      ApiService.CreateQuery(query)
        .then((r) => {
          publishEvent<IQuery>(QUERY_CREATED, r.data);
          publishEvent<IQuery>(QUERY_SAVED, r.data);
          resolve(r.data);
        })
        .catch((error) => {
          publishEvent<IQuerySavingFailure>(QUERY_SAVED, {
            error,
            query: query,
          });
          reject(error);
        });
    });
  }

  public saveQuery(): Promise<IQuery> {
    publishEvent<IQuery>(QUERY_SAVING, this.query);
    return new Promise<IQuery>((resolve, reject) => {
      ApiService.SaveQuery(this.query)
        .then((r) => {
          publishEvent<IQuery>(QUERY_SAVED, r.data);
          resolve(r.data);
        })
        .catch((error) => {
          publishEvent<IQuerySavingFailure>(QUERY_SAVED, {
            error,
            query: this.query,
          });
          reject(error);
        });
    });
  }

  public static Delete(id: string) {
    const qs = this.Get(id);
    return new Promise<void>((resolve, reject) => {
      if (!qs) {
        reject();
      } else {
        ApiService.DeleteQuery(qs.query)
          .then((r) => {
            publishEvent<IQuery>(QUERY_DELETED, qs.query);
            this.services.splice(
              this.services.findIndex((q) => q.query.id === qs.query.id),
              1,
            );
            resolve();
          })
          .catch((error) => {
            reject();
          });
      }
    });
  }
}
