import { category, PlButton } from '../index';
import { ComponentService, IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 21" fill="none">
<g clip-path="url(#clip0_1_27)">
<path fill="currentColor" d="M15.6646 20.0731C15.3499 20.0716 15.4998 20.0723 15.4998 20.0723C15.3826 20.0718 15.2733 20.0712 15.2733 20.0712L14.9998 20.07L4.99995 20.0232L4.95034 20.0229C4.95034 20.0229 4.86323 20.0225 4.75385 20.022C4.62105 20.0214 4.5594 20.0211 4.3936 20.0203C4.22781 20.0196 3.99996 20.0185 3.99996 20.0185L3.8984 20.018L3.74996 20.0173L2.49998 20.0115C1.83699 20.0084 1.20241 19.7245 0.735853 19.2222C0.269398 18.7199 0.00917443 18.0404 0.0124838 17.3331L0.0811132 2.66664C0.0844224 1.95945 0.350994 1.28239 0.822129 0.784433C1.29336 0.28658 1.93057 0.00859812 2.59356 0.0117005L17.5934 0.0818897C18.2564 0.0849922 18.891 0.368925 19.3575 0.871166C19.824 1.37351 20.0842 2.05303 20.0809 2.76022L20.0123 17.4267C20.009 18.134 19.7424 18.811 19.2712 19.3089C18.8 19.8068 18.1628 20.0848 17.4998 20.0817L16.2498 20.0758L15.9998 20.0746C15.9998 20.0746 15.8303 20.0739 15.6646 20.0731ZM17.501 15.088C18.2081 15.0877 18.788 14.5275 18.8126 13.8208L18.8275 13.3941L18.8309 2.75438C18.8326 2.40078 18.7025 2.06097 18.4692 1.80985C18.236 1.55872 17.9187 1.41676 17.5872 1.41521L2.58732 1.34502C2.25583 1.34347 1.93717 1.48246 1.70161 1.73138C1.46604 1.98031 1.33275 2.31889 1.3311 2.67249L1.26918 13.3996L1.26909 13.6913C1.26888 14.4171 1.85728 15.0055 2.58304 15.0053V15.0053L9.5 15L17.501 15.088V15.088Z"/>
<path fill="currentColor" d="M9.44886 7.98864C9.44886 6.08523 8.26704 5 6.72443 5C5.17329 5 4 6.08523 4 7.98864C4 9.88353 5.17329 10.9772 6.72443 10.9772C8.26704 10.9772 9.44886 9.89205 9.44886 7.98864ZM8.2017 7.98864C8.2017 9.22159 7.61648 9.88921 6.72443 9.88921C5.82954 9.88921 5.24716 9.22159 5.24716 7.98864C5.24716 6.75568 5.82954 6.08807 6.72443 6.08807C7.61648 6.08807 8.2017 6.75568 8.2017 7.98864ZM10.2601 10.8977L11.4902 10.8977L11.4902 9.24432L12.0953 8.50568L13.6891 10.8977L15.1607 10.8977L12.976 7.68466L15.1351 5.07955L13.6607 5.07955L11.5669 7.64489L11.4902 7.64489L11.4902 5.07955L10.2601 5.07955L10.2601 10.8977Z"/>
</g>
<defs>
<clipPath id="clip0_1_27">
<rect width="20" height="20" fill="white" transform="translate(19.9998 20.0934) rotate(-179.732)"/>
</clipPath>
</defs>
</svg>
`

export const PlButtonConfig: IComponentsBundleItem = {
  id: 'button',
  name: 'Button',
  tag: 'pl-button',
  container: false,
  category: category.buttons,
  svgIcon: svg,
  defaults: {
    innerHtml: 'Button Text',
  },
  events: ['click'],
  configurators: {
    enable: [
      {
        id: 'innerHtml',
        config: {
          label: 'Button text',
          placeholder: 'Enter button text',
        },
      },
      {
        id: 'select',
        config: {
          attribute: 'variant',
          default: 'filled',
          label: 'Variant',
          options: {
            filled: 'Filled',
            outline: 'Outline',
            transparent: 'Transparent',
            underline: 'Underline',
          },
        },
      },
      {
        id: 'fontSize',
      },
      {
        id: 'icon',
        config: {
          label: 'Button icon',
          attribute: 'icon',
        },
      },
      {
        id: 'boolean:loading',
        config: {
          attribute: 'loading',
          label: 'Loading',
        },
      },
      {
        id: 'boolean:ripple',
        config: {
          attribute: 'ripple',
          label: 'Ripple effect',
        },
      },
      {
        id: 'text:tooltip',
        config: {
          attribute: 'tooltip',
          label: 'Tooltip',
          placeholder: '',
        },
      },
      {
        id: 'visibility',
      },
      {
        id: 'actions',
        config: {
          event: 'click',
        },
      },
    ],
  },
  variables: [
    { name: 'buttonText', bind: 'innerHtml' },
    { name: 'disabled', bind: 'attribute' },
    { name: 'icon', bind: 'attribute', bindName: 'icon' },
    { name: 'loading', bind: 'attribute', bindName: 'loading' },
    { name: 'click', bind: 'method' },
    { name: 'setText', bind: 'method', methodParams: { text: 'string' } },
  ],
  setContent: (e: HTMLElement, c: ComponentService, content: string) => {
    if (e.childNodes.length) {
      (e as PlButton).setText(content);
    } else {
      (e as PlButton).innerHTML = content;
    }
  },
};
