import { VariablesLayer } from './VariablesLayer';
import { AppService, ComponentService } from './';
import { IPlatmaAppComponent } from '../interfaces/component/IPlatmaAppComponent';
import {
  COMPONENT_DELETED_EVENT,
  COMPONENT_INSERTED_EVENT,
  COMPONENT_MOVED_EVENT,
  ComponentDeletedEvent,
  ComponentInsertedEvent,
  ComponentMovedEvent,
  publishEvent,
} from '../events';
import { IComponentsBundleItem } from '../interfaces/bundles/IComponentsBundle';
import lodashCloneDeep from 'lodash/cloneDeep';
import { PlatmaRegistry } from '../Registry';

declare var PLATMA_REGISTRY: PlatmaRegistry;

export class ContainerLayer extends VariablesLayer {
  public children: ComponentService[] = [];

  constructor(
    public screenId: string,
    public structure: IPlatmaAppComponent,
    public config: IComponentsBundleItem,
  ) {
    super(screenId, structure, config);
  }

  public DeleteChild(id: string) {
    if (!this.component) return;
    const i = this.children.findIndex((c) => c.id === id);
    if (i !== undefined && i >= 0) {
      this.children[i].Delete();
      this.children.splice(i, 1);
      super.DeleteChild(id);
      AppService.getScreen(this.screenId).SetSelectedComponentId(null);
      publishEvent<ComponentDeletedEvent>(COMPONENT_DELETED_EVENT, {
        screenId: this.screenId,
        parent: this.component?.parent,
        componentId: this.component?.id,
      });
    }
  }

  public DuplicateChild(id: string) {
    const i = this.children.findIndex((c) => c.id === id);
    if (i !== undefined && i >= 0) {
      const cs = this.children[i];
      const structure = lodashCloneDeep(cs.structure);
      if (i == this.children.length - 1) {
        this.AppendTo(cs.parent ? cs.parent.id : '', structure);
      } else {
        const rcs = this.children[i + 1];
        this.InsertBefore(cs.parent ? cs.parent.id : '', rcs.id, structure);
      }
    }
  }

  public InsertBefore(
    parentId: string,
    referenceId: string,
    config: IPlatmaAppComponent,
  ) {
    if (
      !this.screen ||
      !this.screen.structure ||
      !this.screen.structure.children
    )
      return;
    this.recursivelyUpdateIDs(config);
    config = this.applyDefaults(config);
    if (!this.component) {
      const rei = this.screen.structure.children.findIndex(
        (c) => c.id === referenceId,
      );
      if (rei != undefined) {
        this.InsertChild(config, rei, referenceId);
      }
    } else if (this.screen && this.component) {
      if (parentId) {
        const parent = this.screen.Component(parentId)?.component;
        if (parent) {
          const rei = parent.children.findIndex((c) => c.id === referenceId);
          parent.InsertChild(config, rei, referenceId);
        }
      } else {
        const rei = this.screen.children.findIndex((c) => c.id === referenceId);
        this.screen.InsertChild(config, rei, referenceId);
      }
    }
  }

  public InsertChild(c: IPlatmaAppComponent, index: number, refid: string) {
    if (!this.htmlElement) return;
    if (
      !this.screen ||
      !this.screen.structure ||
      !this.screen.structure.children
    )
      return;
    if (
      this.component &&
      this.component.children &&
      this.component.structure.children
    ) {
      this.component.structure.children.splice(index, 0, c);
      const nc = new ComponentService(
        this.screen.id,
        c,
        this.component.structure,
      );
      if (nc && nc.config) {
        nc.SetScreenService(this.screen);
        this.children.splice(index, 0, nc);
        nc.SetParentElement(this.htmlElement);
        nc.CreateElement();
        nc.InsertIntoParentBeforeChild(refid);
        nc.Render();
      }
    } else {
      const nc = new ComponentService(this.screen.id, c, this.screen.structure);
      if (nc && nc.config) {
        nc.SetScreenService(this.screen);
        this.screen.structure.children.splice(index, 0, c);
        this.children.splice(index, 0, nc);
        nc.SetParentElement(this.htmlElement);
        nc.CreateElement();
        nc.InsertIntoParentBeforeChild(refid);
        nc.Render();
      }
    }
  }

  public AppendChild(config: IPlatmaAppComponent) {
    if (!this.screen || !this.screen.structure) return;
    config = this.applyDefaults(config);
    if (!this.structure.children) {
      this.structure.children = [];
    }
    this.recursivelyUpdateIDs(config);
    const cs = this.structure.children;
    cs.push(config);
    const nc = new ComponentService(this.screen.id, config, this.structure);
    nc.SetScreenService(this.screen);
    this.children.push(nc);
    if (this.htmlElement) nc.SetParentElement(this.htmlElement);
    if (config.children) nc.InitChildren(config);
    nc.AppendToParent();
    nc.Render();
  }

  public AppendTo(parentId: string, config: IPlatmaAppComponent) {
    if (!this.htmlElement) return;
    if (
      !this.screen ||
      !this.screen.structure ||
      !this.screen.structure.children
    )
      return;
    const parent = parentId ? this.screen.Component(parentId) : this.screen;
    if (!parent) return;
    if (parent.structure.container && !parent.structure.children) {
      parent.structure.children = [];
    }
    const cs = parent.structure.children;
    if (cs) {
      parent.AppendChild(config);
    }
    publishEvent<ComponentInsertedEvent>(COMPONENT_INSERTED_EVENT, {
      parent: null,
      config,
      screenId: this.screen.id,
    });
  }

  public MoveInSameParent(
    parentId: string,
    config: IPlatmaAppComponent,
    oldIndex: number,
    newIndex: number,
  ) {
    if (!this.screen) return;
    const screenId = this.structure.id;
    let cs = this.structure.children;
    if (!cs) return;
    let parent: IPlatmaAppComponent | null = null;
    if (parentId) {
      const parent = this.screen.Component(parentId);
      if (parent && parent.component) {
        cs = parent.structure.children as IPlatmaAppComponent[];
      }
    }
    cs.splice(newIndex, 0, cs.splice(oldIndex, 1)[0]);
    publishEvent<ComponentMovedEvent>(COMPONENT_MOVED_EVENT, {
      oldParent: parent,
      newParent: parent,
      config,
      screenId,
    });
  }

  public MoveBetweenParents(
    oldParentId: string,
    newParentId: string,
    config: IPlatmaAppComponent,
    oldIndex: number,
    newIndex: number,
  ) {
    if (!this.screen) return;
    const screenId = this.structure.id;
    let oldParent: ComponentService | null = null;
    let oldCs: IPlatmaAppComponent[] | undefined;
    let newParent: ComponentService | null = null;
    let newCs: IPlatmaAppComponent[] | undefined;
    if (oldParentId) {
      oldParent = this.screen.Component(oldParentId);
      if (oldParent && oldParent.component) {
        oldCs = oldParent.component.structure.children as IPlatmaAppComponent[];
      }
    } else {
      oldCs = this.structure.children;
    }
    if (newParentId) {
      newParent = this.screen.Component(newParentId);
      if (newParent && newParent.component) {
        if (!Array.isArray(newParent.children)) {
          newParent.children = [];
        }
        if (!Array.isArray(newParent.structure.children)) {
          newParent.structure.children = [];
        }
        newCs = newParent.structure.children as IPlatmaAppComponent[];
      }
    } else {
      newCs = this.structure.children;
    }
    if (oldCs) {
      const cut = oldCs.splice(oldIndex, 1);
      const cmpFromOldParent = cut.length > 0 ? cut[0] : null;
      if (newCs && cmpFromOldParent && oldParent && newParent) {
        newCs.splice(newIndex, 0, cmpFromOldParent);
        publishEvent<ComponentMovedEvent>(COMPONENT_MOVED_EVENT, {
          oldParent: oldParent.structure,
          newParent: newParent.structure,
          config,
          screenId,
        });
      }
    }
  }

  protected Find(id: string): ComponentService | undefined {
    let r: ComponentService | undefined = undefined;
    this.children.forEach((c) => {
      if (c.id === id) {
        r = c;
      } else {
        const x = c.Find(id);
        if (x) {
          r = x;
        }
      }
    });
    return r;
  }

  public InitChildren(c: IPlatmaAppComponent | null) {
    if (!c) return;
    if (!Array.isArray(c.children)) return;
    c.children.forEach((cc) => {
      if (!this.screenId) return;
      if (!this.component) return;
      cc.locked = this.component.structure.locked;
      const nc = new ComponentService(this.screenId, cc, c);
      if (this.screen) nc.SetScreenService(this.screen);
      if (Array.isArray(cc.children)) nc.InitChildren(cc);
      this.children.push(nc);
    });
  }

  protected recursivelyUpdateIDs(
    config: IPlatmaAppComponent,
    stack: IPlatmaAppComponent[] = [],
  ) {
    if (
      !this.screen ||
      !this.screen.structure ||
      !this.screen.structure.children
    )
      return;
    // find component config
    const cc = PLATMA_REGISTRY.GetAllComponents().find(
      (cc) => cc.tag == config.tag,
    );
    // if found - just set id to one from bundle
    if (cc) config.id = cc.id;
    config.id += this.getNewID(
      config.id,
      this.screen.structure.children,
      1,
      stack,
    );
    stack.push(config);
    if (config.children && Array.isArray(config.children)) {
      config.children.forEach((c) => stack.push(c));
      config.children.forEach((c) => this.recursivelyUpdateIDs(c, stack));
    }
    return config;
  }

  protected getNewID(
    prefix: string,
    cs: IPlatmaAppComponent[],
    i = 1,
    stack: IPlatmaAppComponent[] = [],
  ) {
    const doOne = (c: IPlatmaAppComponent) => {
      let rid = c.id.replace(prefix, '');
      if (!isNaN(Number(rid)) && Number(rid) >= i) {
        const xi = Number(rid);
        if (xi >= i) {
          i = xi + 1;
        }
      }
    };

    const doChildren = (c: IPlatmaAppComponent) => {
      if (c.children && c.children.length > 0) {
        const xi = this.getNewID(prefix, c.children, i);
        if (xi > i) {
          i = xi;
        }
      }
    };

    cs.forEach((c) => {
      if (c.id.startsWith(prefix)) {
        doOne(c);
      }
      doChildren(c);
    });

    stack.forEach((c) => {
      if (c.id.startsWith(prefix)) {
        doOne(c);
      }
      doChildren(c);
    });

    return i;
  }
}
