import { IComponentsBundleItem } from '@mf-ide/common';
import { category } from '../index';

//language=xml
const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg>
`;

export const PlArrowCardConfig: IComponentsBundleItem = {
  id: 'arrowCard',
  tag: 'pl-arrow-card',
  name: 'Arrow Card',
  category: category.cards,
  container: false,
  svgIcon: svg,
  defaults: {
    style:{
      "border-radius":"1rem",
      "background-color":"var(--pl-color-body)"
    }
  },
  events: [],
  configurators:{
    enable:[
      {
        id: 'text:title',
        config: {
          attribute: 'title',
          label: 'Title',
        },
      },
      {
        id: 'text:subtitle',
        config: {
          attribute: 'subtitle',
          label: 'Subtitle',
        },
      },
      {
        id: 'innerHtml',
        config: {
          label: 'Content',
        },
      },
      {
        id: 'icon',
        config: {
          label: 'Card icon',
          attribute: 'icon',
        },
      },
      {
        id: 'boolean:loading',
        config: {
          attribute: 'loading',
          label: 'Loading',
        },
      },
      {
        id: 'visibility',
      },
    ]
  },
  variables: []
};
