import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';
import { PlCol } from '../box/pl-box';

@defineCustomElement('pl-radio')
export class PlRadio extends HTMLElement {
  private ___observer: MutationObserver;
  private ___multiple: boolean = false;
  private ___label = '';
  private connected = false;
  private input: HTMLInputElement | null = null;
  private label: HTMLLabelElement | null = null;
  private col: PlCol | null = null;

  constructor() {
    super();
    this.___observer = new MutationObserver((list) =>
      this.observeCallback(list),
    );
  }

  observeCallback(mutationList: MutationRecord[]) {
    let refresh = false;
    for (let i = 0; i < mutationList.length; i++) {
      const mi = mutationList[i];
      if (mi.type === 'childList') {
        mi.addedNodes.forEach((n) => {
          if (n.parentElement === this) {
            refresh = true;
          }
        });
        mi.removedNodes.forEach((n) => {
          if (n.parentElement === this) {
            refresh = true;
          }
        });
      }
    }
    if (refresh) {
      this.handleOptions();
    }
  }

  handleOptions() {
    if (this.col) {
      this.col.innerHTML = '';
    }
    this.querySelectorAll('pl-option').forEach((option) => {
      const value = option.getAttribute('value');
      const label = option.innerHTML;
      const row = document.createElement('pl-row');
      const inputElement = document.createElement('input');
      inputElement.type = 'radio';
      inputElement.name = this.id;
      inputElement.value = value ?? '';
      inputElement.id = crypto.randomUUID();
      const labelElement = document.createElement('label');
      labelElement.htmlFor = inputElement.id;
      labelElement.innerHTML = label;
      row.append(inputElement);
      row.append(labelElement);
      this.col?.append(row);
    });
  }

  @attribute('label') setLabel(v: string | null) {
    this.___label = v ?? '';
    if (this.label) {
      this.label.innerHTML = this.___label;
    }
  }

  @attribute('multiple') setMultiple(v: string | null) {
    this.___multiple = v === 'true';
    this.render();
  }

  render() {
    this.col = this.querySelector(':scope>pl-col');
    this.input = this.querySelector(':scope>input');
    this.label = this.querySelector(':scope>label');
    if (this.___multiple) {
      if (this.input) {
        this.input.remove();
      }
      if (this.label) {
        this.label.remove();
      }
      if (!this.col) {
        this.col = document.createElement('pl-col') as PlCol;
      }
      this.append(this.col);
    } else {
      if (this.col) {
        this.col.remove();
      }
      if (!this.input) {
        this.input = document.createElement('input');
        this.input.type = 'radio';
        this.input.id = crypto.randomUUID();
      }
      if (!this.label) {
        this.label = document.createElement('label');
        this.label.htmlFor = this.input.id;
        this.label.innerHTML = this.___label;
      }
      this.append(this.input);
      this.append(this.label);
    }
  }

  connectedCallback() {
    if (!this.connected) {
      this.setMultiple(this.getAttribute('multiple'));
      this.render();
      this.setLabel(this.getAttribute('label'));
      this.connected = true;
      this.___observer.observe(this, {
        attributes: false,
        childList: true,
        subtree: true,
      });
    }
  }

  disconnectedCallback() {
    this.___observer.disconnect();
  }
}
