import { IPlatmaAppScreenSettings } from '../interfaces/screen/IPlatmaAppScreenSettings';
import { publishEvent } from './publishEvent';

export const SCREEN_SETTINGS_UPDATED_EVENT = 'screen-settings-updated';
export const SCREEN_STYLE_UPDATED_EVENT = "screen-style-updated"
export const SCREEN_ADDED_EVENT = "screen-added-updated"
export const SCREEN_DELETE_REQUEST_EVENT = "screen-delete-request"
export const SCREEN_DELETED_EVENT = "screen-deleted"
export const SCREEN_CONTENT_CHANGED_EVENT = "screen-content-changed"

export interface ScreenSettingsUpdated {
  screenId: string,
  settings: IPlatmaAppScreenSettings
}

export interface ScreenStyleUpdated {
  screenId: string,
  style: {[index:string]:string}
}

export function pubScreenContentChanged(screenId:string) {
  publishEvent<string>(SCREEN_CONTENT_CHANGED_EVENT, screenId)
}

export function pubScreenAdded(screenId:string) {
  publishEvent<string>(SCREEN_ADDED_EVENT, screenId)
}

export function pubScreenSettingsUpdated(detail:ScreenSettingsUpdated) {
  publishEvent<ScreenSettingsUpdated>(SCREEN_SETTINGS_UPDATED_EVENT, detail)
}

export function pubScreenStyleUpdated(detail:ScreenStyleUpdated) {
  publishEvent<ScreenStyleUpdated>(SCREEN_STYLE_UPDATED_EVENT, detail)
}

export function pubScreenDeleteRequest(screenId:string) {
  publishEvent<string>(SCREEN_DELETE_REQUEST_EVENT, screenId)
}

export function pubScreenDeleted(screenId:string) {
  publishEvent<string>(SCREEN_DELETED_EVENT, screenId)
}
