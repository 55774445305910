import { category } from '../index';
import { IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none">
<path fill="currentColor" d="M18.3333 0H1.66667C1.22464 0 0.800716 0.175595 0.488155 0.488155C0.175595 0.800716 0 1.22464 0 1.66667V18.3333C0 18.7754 0.175595 19.1993 0.488155 19.5118C0.800716 19.8244 1.22464 20 1.66667 20H18.3333C18.7754 20 19.1993 19.8244 19.5118 19.5118C19.8244 19.1993 20 18.7754 20 18.3333V1.66667C20 1.22464 19.8244 0.800716 19.5118 0.488155C19.1993 0.175595 18.7754 0 18.3333 0ZM1.66667 18.3333V1.66667H18.3333V18.3333H1.66667Z"/>
<path fill="currentColor" d="M8.33333 14.5833L4.16667 10.45L5.49167 9.16667L8.33333 11.9583L14.5083 5.83333L15.8333 7.15L8.33333 14.5833Z"/>
</svg>`

export const PlCheckboxConfig: IComponentsBundleItem = {
  id: 'checkbox',
  name: 'Checkbox',
  tag: 'pl-checkbox',
  container: false,
  category: category.forms,
  svgIcon: svg,
  defaults: {
    attributes: {
      gap: 'xs',
      label: 'Checkbox label',
      checked: 'false',
    },
  },
  events: [],
  configurators: {
    enable: [
      {
        id: 'text',
        config: {
          attribute: 'label',
          label: 'Label',
          placeholder: 'Checkbox label',
        },
      },
      {
        id: 'boolean',
        config: {
          attribute: 'checked',
          label: 'Checked',
        },
      },
      {
        id: 'fontSize',
      },
      {
        id: 'bind',
        config: {
          types: ['bool', 'array'],
        },
      },
      {
        id: 'visibility',
      },
      {
        id: 'actions',
        config: {
          event: 'change',
        },
      },
    ],
    disable: ['border', 'shadow'],
  },
  variables: [
    { name: 'label', bind: 'attribute', bindName: 'label' },
    {
      name: 'checked',
      bind: 'attribute',
      bindName: 'checked',
      type: 'boolean',
    },
    { name: 'setLabel', bind: 'method', methodParams: { label: 'string' } },
    {
      name: 'setChecked',
      bind: 'method',
      methodParams: { checked: 'boolean' },
    },
  ],
};
