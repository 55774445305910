import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';

@defineCustomElement('pl-divider')
export class PlDivider extends HTMLElement {
  connected = false;
  vertical = false;
  size = '1px';
  color = 'var(--pl-color-default-border)';

  attributeChangedCallback() {}

  @attribute('vertical') setVertical(_: string | null) {
    this.upd();
  }

  @attribute('size') setSize(_: string | null) {
    this.upd();
  }

  @attribute('color') setColor(_: string | null) {
    this.upd();
  }

  upd() {
    this.vertical = this.getAttribute('vertical') !== null;
    const s = this.getAttribute('size');
    this.size = s ?? '1px';
    const c = this.getAttribute('color');
    this.color = c ?? 'var(--pl-color-default-border)';
    this.style.borderColor = this.color;
    this.style.borderWidth = this.size;
    this.style.borderTopStyle = this.vertical ? 'none' : 'solid';
    this.style.borderRightStyle = this.vertical ? 'solid' : 'none';
    this.style.display = this.vertical ? 'inline' : 'block';
  }

  connectedCallback() {
    if (!this.connected) {
      this.upd();
    }
  }
}
