import { defineCustomElement } from '../_decorators/element';

@defineCustomElement('pl-box')
export class PlBox extends HTMLElement {
  protected connected = false;
}

@defineCustomElement("pl-row")
export class PlRow extends PlBox {
}

@defineCustomElement("pl-col")
export class PlCol extends PlBox {
}
