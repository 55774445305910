import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';
import { PlAlertToastStack } from './pl-alert-toast-stack';

const variantIcons: { [index: string]: string } = {
  info: 'bi bi-info-circle',
  success: 'bi bi-check-circle',
  neutral: 'bi bi-exclamation-square',
  warning: 'bi bi-exclamation-triangle',
  error: 'bi bi-exclamation-octagon',
};

export const PlAlertPositions = [
  "top",
  "top-right",
  "bottom-right",
  "bottom",
  "bottom-left",
  "top-left"
]

@defineCustomElement("pl-alert")
export class PlAlert extends HTMLElement {

  private readonly icon: HTMLElement
  private readonly col: HTMLElement
  private readonly text: HTMLElement
  private readonly header: HTMLElement
  private readonly closeButton: HTMLElement
  private readonly spinner: HTMLElement
  private closeAction: string = ""
  private variant = ""
  private iconClass = ""
  public position:string|undefined
  public duration:number|undefined

  constructor() {
    super();
    this.spinner = document.createElement("pl-spinner")
    this.spinner.setAttribute("overlay", "")
    this.spinner.setAttribute("fs", "2x")
    this.spinner.style.display = "none"
    this.col = document.createElement("pl-col")
    this.icon = document.createElement("i")
    this.header = document.createElement("header")

    this.text = document.createElement("pl-text")
    while (this.childNodes.length>0) {
      this.text.append(this.childNodes.item(0))
    }

    this.closeButton = document.createElement("pl-close-button")
    this.col.append(this.header)
    this.col.append(this.text)
  }

  @attribute("icon") setIcon(v: string | null) {
    if (v) {
      this.icon.className = v
      this.iconClass = v
    } else if (this.variant in variantIcons) {
      this.icon.className = variantIcons[this.variant]
    }
  }

  @attribute("variant") setVariant(v: string | null) {
    this.variant = v ? (Object.keys(variantIcons).indexOf(v.toString()) >= 0 ? v : "info") : "info"
    if (!this.iconClass && variantIcons[this.variant]) {
      this.icon.className = variantIcons[this.variant]
    }
  }

  @attribute("title") setTitle(v: string | null) {
    this.header.innerText = v ?? ""
  }

  @attribute("close-action") setCloseAction(v: string | null) {
    this.closeAction = v ?? ""
  }

  @attribute("closable") setClosable(v: string | null) {
    this.closeButton.style.display = v === "true" ? "flex" : "none"
  }

  @attribute("loading") setLoading(v: string | null) {
    this.spinner.style.display = (v && v === "true") ? "flex" : "none"
  }

  @attribute("position") setPosition(v: string|null) {
    this.position = v?v:"top-right"
  }

  @attribute("duration") setDuration(v: string|null) {
    this.duration = !isNaN(Number(v))?Number(v):0
  }

  public close() {
    if (!this.closeAction) {
      this.remove()
    } else if (this.closeAction === "hide") {
      this.setAttribute("visible", "false")
    }
  }

  connectedCallback() {
    this.setCloseAction(this.getAttribute("close-action"))
    this.setIcon(this.getAttribute("icon"))
    this.setVariant(this.getAttribute("variant"))
    this.setClosable(this.getAttribute("closable"))
    this.setTitle(this.getAttribute("title"))
    this.append(this.spinner)
    this.append(this.icon)
    this.append(this.col)
    this.append(this.closeButton)
    this.closeButton.addEventListener("click", this.close)
  }

  disconnectedCallback() {
    if (this.closeButton) {
      this.closeButton.removeEventListener("click", this.close)
    }
  }

  public setText(text: string) {
    this.text.innerHTML = text
  }

  public toast() {
    PlAlertToastStack.AppendAlert(this, 2500)
  }
}
Object.defineProperty(PlAlert.prototype, 'innerHTML', {
  set: function (value) {
    if (this.text) {
      this.text.innerHTML = value
    }
  },
  get: function () {
    return this.text?this.text.innerHTML:""
  }
});