import { category } from '../index';
import { IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <g>
            <path fill-rule="evenodd"
                  d="M12 19a7 7 0 1 0 0-14a7 7 0 0 0 0 14m0 3c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"
                  clip-rule="evenodd" opacity="0.2"/>
            <path d="M2 12C2 6.477 6.477 2 12 2v3a7 7 0 0 0-7 7z"/>
        </g>
    </svg>`;

export const PlSpinnerConfig: IComponentsBundleItem = {
  id: 'spinner',
  name: 'Spinner',
  tag: 'pl-spinner',
  container: false,
  category: category.primitives,
  svgIcon: svg,
  defaults: {},
  events: [],
  configurators: {
    enable: [
      {
        id: 'fontSize',
      },
      {
        id: 'visibility',
      },
    ],
  },
};
