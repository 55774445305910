import { IPlatmaAppComponent } from '../interfaces/component/IPlatmaAppComponent';
import {
  COMPONENT_ATTRIBUTE_CHANGED_EVENT,
  COMPONENT_CONFIGURATION_CHANGED_EVENT,
  COMPONENT_STYLE_CHANGED_EVENT,
  ComponentChangedEvent,
  IVariableEvent,
  publishEvent,
  pubScreenContentChanged,
} from '../events';
import { IComponentsBundleItem } from '../interfaces/bundles/IComponentsBundle';
import { ContainerLayer } from './ContainerLayer';
import { AppService, BroadcastService } from './';
import { PlatmaRegistry } from '../Registry';
import { IPlatmaAppScreenType } from '../interfaces/screen/IPlatmaAppScreenType';
import { IPlatmaAppScreenSettings } from '../interfaces/screen/IPlatmaAppScreenSettings';
import lodashGet from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { IActionsBundleItem } from '../interfaces/bundles/IActionsBundle';
import { IPlatmaAppEvent } from '../interfaces/events/IPlatmaAppEvent';
import { PlRender } from '../render';

declare var PLATMA_REGISTRY: PlatmaRegistry;

export class ComponentService extends ContainerLayer {
  public interactive = true;

  public data: any;
  public data_index = -1;

  public repeaterChild = false;
  public repeaterParent: ComponentService | undefined;
  private repeatedComponents: ComponentService[] = [];
  private assignedEvents: IPlatmaAppEvent[] = [];

  get id(): string {
    if (!this.structure) return '';
    return this.structure.id;
  }

  public get title(): string {
    if (this.structure.settings) {
      return this.structure.settings.title;
    }
    return '';
  }

  public get type(): string {
    if (this.structure && this.structure.type) {
      return this.structure.type;
    }
    return '';
  }

  public get settings(): IPlatmaAppScreenSettings {
    if (this.structure.settings) {
      return this.structure.settings;
    }
    return { title: '', slug: '' };
  }

  public constructor(
    public screenId: string,
    public structure: IPlatmaAppComponent,
    public parent: IPlatmaAppComponent | null,
  ) {
    let config:IComponentsBundleItem = {id:"",name:"",tag:"",container:false,events:[]}

    // if (structure.tag === 'pl-component') {
    //   config = {
    //     id: 'component',
    //     name: '',
    //     tag: 'pl-component',
    //     container: false,
    //     events:[]
    //   };
    // }

    if (structure.tag !== 'pl-component') {
      const cnf = PLATMA_REGISTRY.GetAllComponents().find(
        (c) => c.tag === structure.tag,
      );
      if(cnf) {
        config = cnf
      }
    }

    super(
      screenId,
      structure,
      config
    );
    if (!structure.attributes) {
      structure.attributes = {};
    }
    if (!structure.style) {
      structure.style = {};
    }

    if (structure.type && structure.type == IPlatmaAppScreenType.PAGE) {
      this.variables.id = this.structure.id;
      if (this.structure.settings) {
        this.variables.name = this.structure.settings.title;
        this.variables.handle = this.structure.settings.slug;
      }
      this.variables.variables = {};
    }

    super.SetComponentService(this);
  }

  public ToggleLock() {
    this.structure.locked = !this.structure.locked
    this.pubConfigurationChange()
  }

  private renderRepeatedComponents() {
    const rdata = this.getBindVariableData() as any[];
    rdata.forEach((vv: unknown, i: number) => {
      if (!this.screen) return;
      if (i == 0) {
        return;
      }
      let c = this.repeatedComponents[i];
      if (!c) {
        const s = { ...this.structure };
        s.id += i > 0 ? '-' + i : '';
        s.bindToVariable = undefined;
        s.locked = i > 0;
        const c = new ComponentService(this.screenId, s, this.parent);
        c.SetScreenService(this.screen);
        c.repeaterParent = this;
        c.repeaterChild = true;
        c.data = vv;
        c.data_index = i;
        if (this.parentElement) {
          c.SetParentElement(this.parentElement);
        }
        this.repeatedComponents[i] = c;
        c.CreateElement();
        if (i > 0) {
          this.repeatedComponents[i - 1].InsertAfterMyself(c);
        }
        c.InitChildren(c.structure);
        c.Render();
      } else {
        c.data = vv;
        c.data_index = i;
        c.Render();
      }
    });
    let pop = false;
    while (rdata.length < this.repeatedComponents.length) {
      const cd = this.repeatedComponents.pop();
      if (cd) {
        if (cd != this) {
          if (cd) cd.Delete();
        }
        pop = true;
      }
    }
    if (pop) {
      this.Render();
    }
  }

  private getBindVariableData() {
    if (this.screen && this.structure.bindToVariable) {
      const vars = AppService.GetVariables(this.screen.id);
      return cloneDeep(lodashGet(vars, this.structure.bindToVariable));
    }
    return null;
  }

  private checkIfItIsRepeater() {
    if (this.structure.bindToVariable) {
      const v = this.getBindVariableData();
      if (Array.isArray(v) && !isEqual(this.data, v)) {
        return true;
      }
    }
    return false;
  }

  public Duplicate() {
    if(!this.screen) return
    if(this.parent) {
      this.screen.Component(this.parent.id)?.DuplicateChild(this.id)
    } else {
      this.screen.DuplicateChild(this.id)
    }
  }

  public Render() {
    if (this.screen && this.component) {
      this.CreateElement();
      if (!this.htmlElement) return;

      if (this.checkIfItIsRepeater()) {
        const rdata = this.getBindVariableData();
        if (rdata.length > 0) {
          this.repeatedComponents[0] = this;
          this.data = rdata[0];
          this.data_index = 0;
        } else {
          this.data = undefined;
          this.data_index = -1;
        }
      }

      if(!this.structure.type) {
        this.htmlElement.id = this.id;
      }

      if (this.children.length > 0) {
        this.children.forEach((c) => {
          c.SetParentElement(this.htmlElement as HTMLElement);
          c.data = this.data;
          c.data_index = this.data_index;
          c.Render();
        });
      } else if ('innerHtml' in this.structure) {
        this.EvaluateContent();
      }

      this.SetAttributes(this.structure.attributes);

      this.UpdateLockAttribute();

      this.SetStyles(this.structure.style);

      // TODO: This should go somewhere else TOO
      if (this.screen && this.screen.interactive) {
        const c = this.structure;
        if('events' in c && Array.isArray(c.events)) {
          c.events.forEach(event => {
            // Check if event already assigned
            const ai = this.assignedEvents
              .find(ae => ae.code == event.code && ae.action && event.action && ae.action.id == event.action.id)
            if(!ai && event.action && this.htmlElement) {
              this.assignedEvents.push(event)
              const eventAction = event.action
              const action = PLATMA_REGISTRY.GetAllActions().find(
                (aa: IActionsBundleItem) => aa.id === eventAction.id,
              );
              if(action && action.handler && typeof action.handler === 'function') {
                const _handler = () => {
                  if (this.screen) {
                    action.handler(
                      this.screen.htmlElement as PlRender,
                      this.screen.structure,
                      this,
                      eventAction.params ?? {},
                      this.data,
                      this.data_index,
                    );
                  }
                };
                this.htmlElement.addEventListener(event.code, _handler);
              }
            }
          })
        }
      }

      this.AppendToParent();

      this.ExecuteRenderCallback();

      this.EvaluateAttributes();
      this.HandleDynamicVisibility()

      // TODO: This should go somewhere else
      if(this.interactive && this.type === IPlatmaAppScreenType.PAGE) {
        const appSettings = AppService.GetSettings()
        if (appSettings.layout?.sizes?.maxWidth) {
          this.htmlElement.style.width = "100%"
          this.htmlElement.style.maxWidth = appSettings.layout?.sizes?.maxWidth+"px"
          this.htmlElement.style.margin = "0 auto"
        }
      }

      if (this.checkIfItIsRepeater()) {
        this.renderRepeatedComponents();
      }
    }
  }

  public SetContent(value: string) {
    if (this.component) {
      super.SetContent(value)
      this.EvaluateContent();
      this.pubConfigurationChange();
    }
  }

  public SetAttribute(attribute: string, value: string) {
    super.SetAttribute(attribute, value);
    this.pubAttributeChange();
  }

  public SetAttributes(attributes: { [index: string]: string } | undefined) {
    super.SetAttributes(attributes);
    this.pubAttributeChange();
  }

  public SetStyle(name: string, value: string) {
    if (this.structure && this.structure.style) {
      this.structure.style[name] = value;
      super.SetStyle(name, value);
    }
  }

  public SetStyles(styles?: { [index: string]: string }) {
    if (this.component) {
      super.SetStyles(styles);
      this.pubStyleChange();
    }
  }

  public RunMethod(methodName: string, args: {[index:string]:any}) {
    if(!this.htmlElement) return
    // @ts-ignore
    if(methodName in this.htmlElement && typeof this.htmlElement[methodName] === "function") {
      if(typeof args === "object" && Object.keys(args).length > 0) {
        // @ts-ignore
        this.htmlElement[methodName](...Object.values(args))
      } else {
        // @ts-ignore
        this.htmlElement[methodName]()
      }
    }
  }

  public Find(id: string): ComponentService | undefined {
    return this.id === id ? this : super.Find(id);
  }

  public UpdateQueryString() {
    const q:{[index:string]:string} = {}
    const usp = new URLSearchParams(window.location.search)
    usp.forEach((value, key) => {
      q[key] = value
    });
    this.variables["query"] = q
  }

  private pubConfigurationChange() {
    if (this.screen && this.component) {
      const e: ComponentChangedEvent = {
        screenId: this.screen.id,
        config: this.component.structure,
        parent: this.parent,
      };
      publishEvent<ComponentChangedEvent>(
        COMPONENT_CONFIGURATION_CHANGED_EVENT,
        e,
      );
      BroadcastService.PublishMessage(COMPONENT_CONFIGURATION_CHANGED_EVENT, e);
      if(this.screen.type) {
        pubScreenContentChanged(this.screenId)
      }
    }
  }

  private pubAttributeChange() {
    if (this.screen && this.component) {
      const e: ComponentChangedEvent = {
        screenId: this.screen.id,
        config: this.component.structure,
        parent: this.parent,
      };
      publishEvent<ComponentChangedEvent>(COMPONENT_ATTRIBUTE_CHANGED_EVENT, e);
      BroadcastService.PublishMessage(COMPONENT_ATTRIBUTE_CHANGED_EVENT, e);
      if(this.screen.type) {
        pubScreenContentChanged(this.screenId)
      }
    }
  }

  private pubStyleChange() {
    if (this.screen && this.component) {
      const e: ComponentChangedEvent = {
        screenId: this.screen.id,
        config: this.component.structure,
        parent: this.parent,
      };
      publishEvent<ComponentChangedEvent>(COMPONENT_STYLE_CHANGED_EVENT, e);
      BroadcastService.PublishMessage(COMPONENT_STYLE_CHANGED_EVENT, e);
      if(this.screen.type) {
        pubScreenContentChanged(this.screenId)
      }
    }
  }

  protected OnVariableChange(detail: IVariableEvent) {
    if (this.id === detail.componentId) return;
    if (
      this.checkIfItIsRepeater()
      &&
      this.structure.bindToVariable
    ) {
      this.Render();
    } else {
      this.EvaluateAttributes();
      this.EvaluateContent();
      this.HandleDynamicVisibility();
    }
  }

  protected HandleDynamicVisibility() {
    if(this.htmlElement && this.HasConfigAttribute("visibility-expression")) {
      const a = this.GetAttribute("visibility-expression","true")
      const show = a.toLowerCase() === "true"
      if(!show) {
        this.htmlElement.setAttribute("pl-dynamically-hidden","")
      } else {
        this.htmlElement.removeAttribute("pl-dynamically-hidden")
      }
    }
  }
}
