import { AppService, ScreenService } from '../services';
import { EventPrefix, SCREEN_CONTENT_CHANGED_EVENT } from '../events';

export class PlComponent extends HTMLElement {
  private connected = false;
  private screenService: ScreenService | undefined;

  get screenId() {
    return this.getAttribute('screen-id');
  }

  constructor() {
    super();
  }

  connectedCallback() {
    if (this.connected) return;
    const pss = AppService.getApp();
    if (pss && pss.screens) {
      const ps = pss.screens.find((s) => s.id === this.screenId);
      if (ps) {
        const nps = { ...ps };
        delete nps.type;
        this.screenService = new ScreenService(nps);
        this.screenService.SetElement(this);
        this.screenService.SetScreenService(this.screenService);
        this.screenService.InitChildren(ps);
        this.screenService.Render();
      }
    }
    document.addEventListener(
      EventPrefix + SCREEN_CONTENT_CHANGED_EVENT,
      this.syncScreen,
    );
  }

  private syncScreen = (e: Event | CustomEvent) => {
    if (!this.screenService) return;
    const d = (e as CustomEvent).detail;
    if (d && d === this.screenId) {
      this.screenService.Render();
    }
  };

  disconnectedCallback() {
    document.removeEventListener(
      EventPrefix + SCREEN_CONTENT_CHANGED_EVENT,
      this.syncScreen,
    );
  }
}
if (!customElements.get('pl-component')) {
  customElements.define('pl-component', PlComponent);
}
