import {
  AppService,
  ComponentService,
  ExpressionLayer,
  IPlatmaAppComponent,
  PlRender,
} from '@mf-ide/common';

export function NavigateToUrl(
  render: PlRender,
  screen: IPlatmaAppComponent,
  component: ComponentService,
  params: { pageId: string; query: { [index: string]: string } },
  data?: { [index: string]: any },
  data_index?: number,
) {
  if (!render) return;
  if (!render.app) return;
  const s = AppService.getScreen(params.pageId);
  if (s) {
    const ss = s.settings;
    if (ss) {
      if (params.query) {
        const q: { [index: string]: string } = {};
        const vars = AppService.getScreen(screen.id).GetVariables();
        if (data) {
          vars['data'] = data;
          vars['data_index'] = data_index;
        }
        Object.keys(params.query).forEach((k) => {
          q[k] = ExpressionLayer.Eval(params.query[k], vars);
        });
        const usp = new URLSearchParams(q);
        render.app.Navigate(ss.slug + '?' + usp);
      } else {
        render.app.Navigate(ss.slug);
      }
    }
  }
}
