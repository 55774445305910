import { defineCustomElement } from '../_decorators/element';
import { arrow, computePosition, flip, offset, shift } from '@floating-ui/dom';
import { attribute } from '../_decorators/attribute';

@defineCustomElement('pl-tooltip')
export class PlTooltip extends HTMLElement {
  private ___anchor: HTMLElement | null = null;
  private ___arrow: HTMLDivElement | null = null;
  private readonly ___span: HTMLSpanElement | null = null;

  constructor() {
    super();
    this.___span = document.createElement('span');
  }

  @attribute('anchor') setAnchor(v: string | null) {
    if (v) {
      this.___anchor = document.getElementById(v);
      if (this.___anchor) {
        this.___anchor.addEventListener('mouseover', this.handleOver);
        this.___anchor.addEventListener('mouseout', this.handleOut);
      }
    }
  }

  handleOver = (_: Event) => this.update(true);
  handleOut = (_: Event) => (this.style.opacity = '0');

  // noinspection JSUnusedGlobalSymbols
  connectedCallback() {
    if (this.___span) this.append(this.___span);
    this.___arrow = this.querySelector('pl-tooltip-arrow');
    if (this.___arrow === null) {
      this.___arrow = document.createElement(
        'pl-tooltip-arrow',
      ) as HTMLDivElement;
      this.append(this.___arrow);
    }
    this.setAnchor(this.getAttribute('anchor'));
    this.update(false);
  }

  update(show: boolean) {
    if (this.___anchor) {
      computePosition(this.___anchor, this, {
        placement: 'top',
        middleware: [
          flip(),
          shift(),
          offset(6),
          arrow({ element: this.___arrow as Element }),
        ],
      }).then(({ x, y, placement, middlewareData }) => {
        Object.assign(this.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        const { x: arrowX, y: arrowY } = middlewareData.arrow as {
          x: number;
          y: number;
        };

        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[placement.split('-')[0]];

        if (this.___arrow) {
          Object.assign(this.___arrow.style, {
            left: arrowX != null ? `${arrowX}px` : '',
            top: arrowY != null ? `${arrowY}px` : '',
            right: '',
            bottom: '',
            [staticSide as string]: '-4px',
          });
        }

        if (show) {
          this.style.opacity = '1';
        }
      });
    } else {
      this.style.opacity = '0';
    }
  }

  // noinspection JSUnusedGlobalSymbols
  disconnectedCallback() {
    if (this.___anchor) {
      this.___anchor.removeEventListener('mouseover', this.handleOver);
      this.___anchor.removeEventListener('mouseout', this.handleOut);
    }
  }
}
Object.defineProperty(PlTooltip.prototype, 'innerHTML', {
  set: function (value) {
    if(this.___span) {
      this.___span.innerHTML=value
    }
  }
});
