import { PlModalOverlay } from './pl-modal-overlay';
import { PlModalHeader } from './pl-modal-header';
import { defineCustomElement } from '../_decorators/element';

@defineCustomElement('pl-modal')
export class PlModal extends HTMLElement {
  private connected = false;
  private overlay: PlModalOverlay | undefined;
  private header: PlModalHeader | undefined;
  private closeAction: string = '';

  handleClose = (e: Event) => {
    e.stopImmediatePropagation();
    this.dispatchEvent(
      new CustomEvent('close', { bubbles: true, cancelable: true }),
    );
    if (!this.closeAction) {
      this.remove();
    } else if (this.closeAction === 'hide') {
      this.setAttribute('visible', 'false');
    }
  };

  connectedCallback() {
    const ca = this.getAttribute('close-action');
    this.closeAction = ca ? ca : '';
    if (!this.connected) {
      this.overlay = document.createElement('pl-modal-overlay');
      this.connected = true;
      this.prepend(this.overlay);
      this.header = this.querySelector('pl-modal-header') as PlModalHeader;
      this.assignHeader(this.header);
    }
  }

  public assignHeader(header: PlModalHeader) {
    if (header) {
      this.header = header;
      this.header.addEventListener('close', this.handleClose);
    }
  }

  // noinspection JSUnusedGlobalSymbols
  disconnectedCallback() {
    if (this.header) {
      this.header.removeEventListener('close', this.handleClose);
    }
  }
}
