import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';

@defineCustomElement('pl-spinner')
export class PlSpinner extends HTMLElement {
  connected = false;
  trackSize = 'calc(1em / 8)';
  trackColor = 'var(--pl-color-default-border)';
  color = 'var(--pl-primary-color-filled)';

  @attribute('color') setColor(v: string | null) {
    this.color = v ?? '';
    this.render();
  }

  @attribute('track-size') setTrackSize(v: string | null) {
    this.trackSize = v ?? '';
    this.render();
  }

  @attribute('track-color') setTrackColor(v: string | null) {
    this.trackColor = v ?? '';
    this.render();
  }

  render() {
    this.innerHTML = `
<span>
<svg role="progressbar" aria-label="Loading">
  <circle
    fill="none"
    stroke-width="${this.trackSize}"
    r="calc(0.5em - ${this.trackSize} / 2)"
    cx=".5em"
    cy=".5em"
    stroke="${this.trackColor}"
    class="track"></circle>
  <circle
    fill="none"
    stroke-width="${this.trackSize}"
    r="calc(0.5em - ${this.trackSize} / 2)"
    cx=".5em"
    cy=".5em"
    stroke="${this.color}"
    class="indicator"></circle>
</svg>    
</span>
    `;
  }

  connectedCallback() {
    if (!this.connected) {
      this.render();
      this.connected = true;
    }
  }
}
