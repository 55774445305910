import { category } from '../index';
import { IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
        <path d="M9.99996 5.83329C7.69996 5.83329 5.83329 7.69996 5.83329 9.99996C5.83329 12.3 7.69996 14.1666 9.99996 14.1666C12.3 14.1666 14.1666 12.3 14.1666 9.99996C14.1666 7.69996 12.3 5.83329 9.99996 5.83329ZM9.99996 1.66663C5.39996 1.66663 1.66663 5.39996 1.66663 9.99996C1.66663 14.6 5.39996 18.3333 9.99996 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99996C18.3333 5.39996 14.6 1.66663 9.99996 1.66663ZM9.99996 16.6666C6.31663 16.6666 3.33329 13.6833 3.33329 9.99996C3.33329 6.31663 6.31663 3.33329 9.99996 3.33329C13.6833 3.33329 16.6666 6.31663 16.6666 9.99996C16.6666 13.6833 13.6833 16.6666 9.99996 16.6666Z"/>
    </svg>
`;

export const PlRadioConfig: IComponentsBundleItem = {
  id: 'radio',
  name: 'Radio Button',
  tag: 'pl-radio',
  container: false,
  category: category.forms,
  svgIcon: svg,
  defaults: {
    attributes: {
      gap: 'xs',
      label: 'Radio label',
    },
  },
  events: ['change'],
  configurators: {
    enable: [
      {
        id: 'text',
        config: {
          attribute: 'label',
          label: 'Label',
          placeholder: 'Radio button label',
        },
      },
      {
        id: 'fontSize',
      },
      {
        id: 'bind',
        config: {
          types: ['array'],
        },
      },
      {
        id: 'visibility',
      },
    ],
    disable: ['border', 'shadow'],
  },
};
