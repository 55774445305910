import { category } from '../index';
import { ComponentService, IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 14" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6929 0.406086H2.25207C1.92027 0.400265 1.59955 0.520281 1.35949 0.739864C1.11942 0.959446 0.979562 1.26084 0.970215 1.57878V12.0028C0.978938 12.3208 1.1182 12.6228 1.35778 12.8431C1.59736 13.0634 1.91778 13.1843 2.24988 13.1797H19.6907C20.023 13.1855 20.3442 13.0649 20.5839 12.8443C20.8238 12.6238 20.9626 12.3212 20.9702 12.0028V1.57875C20.9621 1.26125 20.8232 0.959721 20.5839 0.740143C20.3445 0.520411 20.0243 0.400266 19.6929 0.406086ZM19.4071 11.489C19.4082 11.5508 19.3814 11.6102 19.3334 11.6515C19.2839 11.6949 19.2195 11.7193 19.1525 11.72L2.75688 11.6515C2.68928 11.6511 2.62463 11.6258 2.57603 11.5809C2.52867 11.54 2.50172 11.4816 2.50219 11.4204V2.04727C2.5039 1.98652 2.53039 1.92889 2.57603 1.88665C2.62541 1.84336 2.68974 1.81888 2.75688 1.81828L19.1593 1.8868C19.2263 1.8874 19.2908 1.91158 19.3402 1.95517C19.3867 1.99756 19.4134 2.05623 19.4139 2.11788L19.4071 11.489Z" fill="#61656F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.97019 3.84499C5.72735 3.67899 5.43559 3.59122 5.13727 3.59451H4.30434V4.23654H5.13727C5.34273 4.21982 5.52592 4.35984 5.55491 4.55539V9.02796C5.52702 9.22441 5.34352 9.36547 5.13727 9.3489H4.30434V9.99093H5.13727C5.43526 9.99392 5.72654 9.90704 5.97019 9.74269C6.21445 9.90585 6.50543 9.99258 6.80328 9.99093H7.63852V9.3489H6.80543C6.59919 9.36547 6.41569 9.22441 6.38779 9.02796V4.5531C6.41677 4.3574 6.59996 4.21753 6.80543 4.23425H7.63836V3.59221H6.80543C6.50635 3.59057 6.21413 3.67914 5.97019 3.84499Z" fill="#61656F"/>
</svg>
`

export const PlInputConfig: IComponentsBundleItem = {
  id: 'input',
  name: 'Input',
  tag: 'pl-input',
  container: false,
  category: category.forms,
  svgIcon: svg,
  defaults: {
    attributes: {
      type: 'text',
      label: 'Input label',
      placeholder: 'Input placeholder',
    },
  },
  events: [],
  configurators: {
    enable: [
      {
        id: 'text:label',
        config: {
          attribute: 'label',
          label: 'Label',
          placeholder: 'Textbox label',
        },
      },
      {
        id: 'text:placeholder',
        config: {
          attribute: 'placeholder',
          label: 'Placeholder',
          placeholder: 'Placeholder text',
        },
      },
      {
        id: 'text:value',
        config: {
          attribute: 'value',
          label: 'Value',
          placeholder: 'Input value',
        },
      },
      {
        id: 'visibility',
      },
      {
        id: 'actions',
        config: {
          event: 'change',
        },
      },
    ],
  },
  variables: [
    { name: 'label', bind: 'attribute', bindName: 'label' },
    { name: 'placeholder', bind: 'attribute', bindName: 'placeholder' },
    { name: 'value', bind: 'attribute', bindName: 'value' },
    { name: 'setText', bind: 'method' },
  ],
  afterCreate: (e: HTMLElement, c: ComponentService) => {
    e.addEventListener('change', (e: Event) => {
      const v = (e.target as HTMLInputElement).value;
      c.SetAttribute('value', v);
    });
  },
};
