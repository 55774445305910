import { defineCustomElement } from '../_decorators/element';
import { PlAlert, PlAlertPositions } from './pl-alert';

@defineCustomElement('pl-alert-toast-stack')
export class PlAlertToastStack extends HTMLElement {
  public position = '';
  public static AppendAlert(alert: PlAlert, duration: number) {
    if (alert) {
      let position = 'top-right';
      if (alert.position) {
        position =
          PlAlertPositions.find((p) => p === alert.position) ?? 'top-right';
      }
      let stack = document.querySelector(
        `pl-alert-toast-stack[position=${position}]`,
      ) as PlAlertToastStack;
      if (!stack) {
        stack = document.createElement(
          'pl-alert-toast-stack',
        ) as PlAlertToastStack;
        stack.setAttribute('position', position);
        document.body.append(stack);
      }
      stack.append(alert);
      if (duration > 0) {
        setTimeout(() => {
          alert.close();
          if (stack.childElementCount === 0) {
            stack.remove();
          }
        }, duration);
      }
    }
  }
}