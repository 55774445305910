import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';
import { PlSpinner } from '../spinner/pl-spinner';
import { PlCol, PlRow } from '../box/pl-box';
import { PlButton } from '../button/pl-button';
import { dc } from '@mf-ide/common/render/tools';

@defineCustomElement('pl-arrow-card')
export class PlArrowCard extends HTMLElement {
  private connected = false;

  private readonly icon: HTMLElement;

  private readonly center: PlRow;
  private readonly _title: HTMLElement;
  private readonly subtitle: HTMLElement;
  private readonly content: HTMLElement;

  private readonly arrow: PlButton;

  private readonly spinner: PlSpinner;

  @attribute('icon') setIcon(icon: string | null) {
    this.icon.className = icon ?? '';
    this.icon.style.display = icon ? 'flex' : 'none';
  }

  @attribute('loading') setLoading(v: string | null) {
    this.spinner.style.display =
      v && (v === 'true' || v !== 'false') ? 'flex' : 'none';
  }

  @attribute('title') setTitle(v: string | null) {
    this._title.innerText = v ?? '';
  }

  @attribute('subtitle') setSubTitle(v: string | null) {
    this.subtitle.innerText = v ?? '';
  }

  constructor() {
    super();
    this.icon = dc('i');
    this.icon.className = 'bi bi-stars';

    this._title = dc('h1');
    this.subtitle = dc('p');
    this.content = dc('div');
    this.center = dc('pl-col') as PlCol;
    this.center.append(this._title);
    this.center.append(this.subtitle);
    this.center.append(this.content);

    this.arrow = dc('pl-button', {
      icon: 'bi bi-chevron-right',
      variant: 'transparent',
    }) as PlButton;
    this.spinner = dc(
      'pl-spinner',
      { overlay: '' },
      { display: 'none', fontSize: '3rem' },
    ) as PlSpinner;
  }

  connectedCallback() {
    if (!this.connected) {
      this.connected = true;
      this.append(this.icon);
      this.append(this.center);
      this.append(this.arrow);
      this.append(this.spinner);
    }
  }
}
Object.defineProperty(PlArrowCard.prototype, 'innerHTML', {
  set: function (value) {
    if (this.content) {
      this.content.innerHTML = value
    }
  },
  get: function () {
    return this.content?this.text.innerHTML:""
  }
});