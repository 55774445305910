import { attribute } from '../_decorators/attribute';
import { defineCustomElement } from '../_decorators/element';

@defineCustomElement('pl-tree-chevron')
export class PlTreeChevron extends HTMLElement {
  private connected = false;

  //language=svg
  private svgml = `
      <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"
           style="width: 1rem; height: 1rem; display: block;">
          <path d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
                fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
      </svg>
  `;
  private svg: HTMLOrSVGImageElement | null = null;

  @attribute('open') setLabel(_: string | null) {
    this.updChevron();
  }

  updChevron() {
    if (!this.svg) {
      return;
    }
    const open = this.getAttribute('open') === 'true';
    if (!open) {
      this.svg.style.transform = 'rotate(-90deg)';
    } else {
      this.svg.style.transform = '';
    }
  }

  connectedCallback() {
    if (!this.connected) {
      this.innerHTML = this.svgml;
      this.svg = this.children.item(0) as HTMLOrSVGImageElement;
      this.updChevron();
    }
  }
}
