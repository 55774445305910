import { PlRender } from './pl-render';
import { InternalVanillaRouter } from './vanilla-router';
import { AppService } from '../services';
import { PlDefaultLayoutHeader } from './pl-default-layout-header';
import { PlDefaultLayoutSidebar } from './pl-default-layout-sidebar';
import { PlDefaultLayoutFooter } from './pl-default-layout-footer';
import { dc } from './tools';

export const ATTR_DISABLE_ROUTER = 'disable-router';

export class PlApp extends HTMLElement {
  // noinspection JSUnusedGlobalSymbols
  static observedAttributes = [ATTR_DISABLE_ROUTER];

  private readonly header: PlDefaultLayoutHeader;
  private readonly row: HTMLElement;
  private readonly sidebar: PlDefaultLayoutSidebar;
  private readonly footer: PlDefaultLayoutFooter;
  private readonly renders:{[index:string]:PlRender} = {}
  private internalRouter: InternalVanillaRouter | undefined;
  public navigateCallback: ((slug: string) => void) | undefined;

  private internalRouterEnabled = true

  // noinspection JSUnusedGlobalSymbols
  attributeChangedCallback(name: string) {
    if(name===ATTR_DISABLE_ROUTER) {
      this.updateDisableInternalRouter()
    }
  }

  constructor() {
    super();
    this.header = dc("pl-default-layout-header") as PlDefaultLayoutHeader
    this.sidebar = dc("pl-default-layout-sidebar") as PlDefaultLayoutSidebar
    this.footer = dc("pl-default-layout-footer")
    this.row = dc('pl-row');
    this.row.append(this.sidebar)
  }

  public Navigate(slug: string) {
    if(this.internalRouterEnabled && this.internalRouter) {
      this.internalRouter.GoTo(slug)
    } else if(this.navigateCallback) {
      this.navigateCallback(slug)
    }
  }

  public SetHeaderDisplay(show:boolean = false) {
    this.header.style.display = show ? '' : "none"
  }

  public SetSidebarDisplay(show:boolean = false) {
    this.sidebar.style.display = show ? '' : "none"
  }

  public SetFooterDisplay(show:boolean = false) {
    this.footer.style.display = show ? '' : "none"
  }

  /*
   * Private methods
   */

  private updateDisableInternalRouter() {
    this.internalRouterEnabled = !this.hasAttribute(ATTR_DISABLE_ROUTER);
  }

  public RenderUrl(url:string) {
    const screen = AppService.FindScreenBySlug(url)
    if(screen) {
      if(!this.renders[screen.id]) {
        this.renders[screen.id] = document.createElement("pl-render") as PlRender
        this.renders[screen.id].app = this
        this.row.append(this.renders[screen.id])
      }
      Object.keys(this.renders).forEach(sid => {
        if(sid === screen.id) {
          this.renders[sid].style.display = "flex"
        } else {
          this.renders[sid].style.display = "none"
        }
      })
      this.renders[screen.id].renderScreen(screen)
      AppService.SetActiveScreenID(screen.id)
      this.sidebar.activeScreenID = screen.id
    } else {
      this.internalRouter?.PageNotFound()
    }
  }

  public Init() {
    const appSettings = AppService.GetSettings()

    this.updateDisableInternalRouter()
    if(this.internalRouterEnabled) {
      this.internalRouter = new InternalVanillaRouter(
        this,
        (p) => this.RenderUrl(p)
      )
    }

    if(appSettings.layout?.header?.enabled) {
      this.append(this.header);
    }
    this.append(this.row)
    this.SetSidebarDisplay(appSettings.layout?.header?.enabled === true)
    this.header.SetHasSidebar(appSettings.layout?.header?.enabled === true)
    if(appSettings.layout?.footer?.enabled) {
      this.append(this.footer)
    }

    // if app settings has body styles - apply
    if(appSettings.body && appSettings.body.style) {
      Object.keys(appSettings.body.style).forEach((k: string) => {
        // @ts-ignore
        const v:string = appSettings.body.style[k]??""
        if(v) {
          // @ts-ignore
          document.body.style[k] = v;
        }
      });
    }
  }

  // noinspection JSUnusedGlobalSymbols
  connectedCallback() {
    this.innerHTML = '';
    if(this.internalRouterEnabled) {
      this.internalRouter = new InternalVanillaRouter(
        this,
        (p) => this.RenderUrl(p)
      )
    }
    this.header.addEventListener("toggle-sidebar", this.toggleSidebar)
  }

  // noinspection JSUnusedGlobalSymbols
  disconnectedCallback() {
    this.header.removeEventListener("toggle-sidebar", this.toggleSidebar)
  }

  private toggleSidebar = () => {
    if(this.sidebar.hasAttribute("open")) {
      this.sidebar.removeAttribute("open")
    } else {
      this.sidebar.setAttribute("open","")
    }
  }
}

if (!customElements.get('pl-app')) {
  customElements.define('pl-app', PlApp);
}
