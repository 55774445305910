import {
  AppService,
  ComponentService,
  IPlatmaAppComponent,
  PlRender,
  QueryService,
} from '@mf-ide/common';

export function RunQuery(
  render: PlRender,
  screen: IPlatmaAppComponent,
  component: ComponentService,
  params: { queryId: string },
  data?: { [index: string]: any },
  data_index?: number,
) {
  const doRun = () => {
    const q: QueryService | undefined = QueryService.Get(params.queryId);
    if (q) {
      const vars = AppService.GetVariables(screen.id);
      if (data) {
        vars['data'] = data;
        vars['data_index'] = data_index;
      }
      q.Run(vars);
    }
  };

  let interval = setInterval(() => {
    if (QueryService.IsInitialized()) {
      doRun();
      clearInterval(interval);
    }
  }, 100);
}
