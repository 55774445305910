export function defineCustomElement(
  tag: any
): (constructor: CustomElementConstructor) => void {
  return (constructor: CustomElementConstructor) => {
    if(!customElements.get(tag)) {
      customElements.define(tag, constructor);
    }
  };
}



