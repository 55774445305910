import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';

@defineCustomElement('pl-input')
export class PlInput extends HTMLElement {
  private connected = false;
  private readonly input: HTMLInputElement;
  private readonly label: HTMLLabelElement;

  @attribute('label') setLabel(v: string | null) {
    this.label.innerHTML = v ? v : '';
  }

  @attribute('value') setValue(v: string | null) {
    this.input.value = v ? v : '';
  }

  @attribute('placeholder') setPlaceholder(v: string | null) {
    this.input.placeholder = v ? v : '';
  }

  get value() {
    return this.input.value;
  }

  set value(value: string) {
    this.input.value = value;
  }

  public setText(value: string) {
    this.input.value = value;
  }

  constructor() {
    super();
    this.input = document.createElement('input');
    this.input.id = crypto.randomUUID();
    this.input.type = 'text';
    this.label = document.createElement('label');
    this.label.htmlFor = this.input.id;
  }

  connectedCallback() {
    if (!this.connected) {
      this.append(this.label);
      this.append(this.input);
      this.connected = true;
    }
  }

  disconnectedCallback() {}
}
