import { attribute } from '../_decorators/attribute';
import { defineCustomElement } from '../_decorators/element';

@defineCustomElement('pl-modal-header')
export class PlModalHeader extends HTMLElement {
  private connected = false;
  private headerTitle: HTMLElement | undefined;
  private headerClose: HTMLElement | undefined;

  handleCloseClick() {
    this.dispatchEvent(
      new CustomEvent('close', {
        bubbles: true,
        cancelable: true,
      }),
    );
  }

  @attribute('heading') setHeading(v: string | null) {
    if (this.headerTitle && v) {
      this.headerTitle.innerHTML = v;
    }
  }

  connectedCallback() {
    if (!this.connected) {
      this.headerTitle = document.createElement('h2');
      this.append(this.headerTitle);
      this.headerClose = document.createElement('pl-close-button');
      this.headerClose.addEventListener('click', () => this.handleCloseClick());
      this.append(this.headerClose);
      this.setHeading(this.getAttribute('heading'));
      this.connected = true;
    }
  }

  // noinspection JSUnusedGlobalSymbols
  disconnectedCallback() {
    if (this.headerClose) {
      this.headerClose.removeEventListener('click', () =>
        this.handleCloseClick(),
      );
    }
  }
}
