import './pl-tree-chevron';
import { PlTreeBranch } from './pl-tree-branch';
import { defineCustomElement } from '../_decorators/element';

@defineCustomElement('pl-tree')
export class PlTree extends HTMLElement {
  public Build(data: IPlTreeItem[]) {
    this.innerHTML = '';
    data.map((item) => {
      const b = new PlTreeBranch();
      b.Build(item);
      this.append(b);
    });
  }

  // connectedCallback() {
  // }
}

export interface IPlTreeItem {
  label: string
  open: boolean
  tree: IPlTreeItem[]
}
