import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';

@defineCustomElement('pl-textarea')
export class PlTextarea extends HTMLElement {
  private connected = false;
  private readonly textarea: HTMLTextAreaElement;
  private readonly label: HTMLLabelElement;

  constructor() {
    super();
    this.textarea = document.createElement('textarea');
    this.textarea.id = crypto.randomUUID();
    this.label = document.createElement('label');
    this.label.htmlFor = this.textarea.id;
  }

  @attribute('label') setLabel(v: string | null) {
    this.label.innerHTML = v ?? '';
  }

  @attribute('placeholder') setPlaceholder(v: string | null) {
    this.textarea.placeholder = v ?? '';
  }

  public setContent(content: string) {
    this.textarea.innerHTML = content;
  }

  connectedCallback() {
    const content = this.innerHTML;
    if (!this.connected) {
      this.innerHTML = '';
      this.setLabel(this.getAttribute('label'));
      this.setPlaceholder(this.getAttribute('placeholder'));
      this.textarea.innerHTML = content;
      this.append(this.label);
      this.append(this.textarea);
      this.connected = true;
    }
  }
}
