import { IPlRenderCallback } from '../interfaces/IPlRenderCallback';
import { AppService, ScreenService } from '../services';
import { IPlatmaAppComponent } from '../interfaces/component/IPlatmaAppComponent';
import { RuntileStylesMediaQuery, RuntimeStyles } from './runtime-styles';
import { IPlatmaAppScreenType } from '../interfaces/screen/IPlatmaAppScreenType';
import { PlApp } from './pl-app';
import { dc } from './tools';

const ATTR_DISABLE_MEDIA_QUERY = 'disable-media-query';
const ATTR_SCREEN_ID = 'screen-id'

export class PlRender extends HTMLElement {
  // noinspection JSUnusedGlobalSymbols
  static observedAttributes = [ATTR_SCREEN_ID,ATTR_DISABLE_MEDIA_QUERY];

  private screenService: ScreenService | null = null;
  public interactive = true;

  public elementRenderCallback: IPlRenderCallback | undefined;
  public elementCreatedCallback: IPlRenderCallback | undefined;

  private readonly runtimestyles: HTMLStyleElement

  public app:PlApp|undefined

  constructor() {
    super();
    this.runtimestyles = dc("style") as HTMLStyleElement
  }

  // noinspection JSUnusedGlobalSymbols
  attributeChangedCallback(name:string, oldValue:string, newValue:string) {
    if(name===ATTR_DISABLE_MEDIA_QUERY) {
      this.updateDisableMediaQuery()
    }
  }

  updateDisableMediaQuery() {
    if(this.hasAttribute(ATTR_DISABLE_MEDIA_QUERY)) {
      this.runtimestyles.innerHTML = RuntimeStyles
    } else {
      this.runtimestyles.innerHTML = RuntimeStyles+RuntileStylesMediaQuery
    }
  }

  // noinspection JSUnusedGlobalSymbols,DuplicatedCode
  connectedCallback() {
    this.updateDisableMediaQuery()

    if(!this.runtimestyles.parentElement) {
      if(this.parentElement) {
        this.parentElement.append(this.runtimestyles)
      }
    }
    const screenId = this.getAttribute(ATTR_SCREEN_ID);
    if (screenId) {
      const ss = AppService.getScreen(screenId);
      if (ss) {
        this.renderScreen(ss.structure);
      }
    }
  }

  public renderScreen(screenToRender: IPlatmaAppComponent) {
    if (!screenToRender) return;
    this.innerHTML = '';
    this.screenService = AppService.getScreen(screenToRender.id);
    if (this.screenService) {

      if(this.interactive) {
        // Inject default app header
        this.app?.SetHeaderDisplay(this.screenService.settings.layout?.header?.enabled)
        this.app?.SetSidebarDisplay(this.screenService.settings.layout?.sidebar?.enabled)
        this.app?.SetFooterDisplay(this.screenService.settings.layout?.footer?.enabled)
      }

      this.screenService.SetElement(this);
      this.screenService.interactive = this.interactive;
      this.screenService.elementRenderCallback = this.elementRenderCallback;
      this.screenService.elementCreatedCallback = this.elementCreatedCallback;
      this.screenService.UpdateQueryString()
      this.screenService.Render();
      if(this.interactive && screenToRender.type === IPlatmaAppScreenType.PAGE) {
        this.dispatchEvent(new CustomEvent("load"));
      }
    }
  }

  public renderModal(screenId: string) {
    const modalScreenService = AppService.getScreen(screenId);

    const modal = dc('pl-modal');
    modal.id = crypto.randomUUID();
    modal.setAttribute('visible', 'true');
    this.append(modal);

    const mkModalHeader = () => {
      const modalHeader = dc('pl-modal-header');
      modalHeader.setAttribute('heading', modalScreenService.title);
      modalHeader.setAttribute(ATTR_SCREEN_ID, modalScreenService.id);
      // @ts-ignore
      modal.assignHeader(modalHeader);
      return modalHeader;
    };

    const mkModalRender = () => {
      const modalRender = new PlRender();
      modalRender.renderScreen(modalScreenService.structure);
      return modalRender;
    };

    const mkModalContainer = () => {
      const col = dc('pl-col');
      col.append(mkModalHeader());
      col.style.top = '10%';
      col.style.position = 'absolute';
      col.append(mkModalRender());
      return col;
    };

    modal.append(mkModalContainer());
  }

  public closeModal(screenId: string) {
    const m = this.querySelector(`pl-modal[${ATTR_SCREEN_ID}=${screenId}]`);
    if (m) {
      m.remove();
    }
  }
}

if (!customElements.get('pl-render')) {
  customElements.define('pl-render', PlRender);
}
