import { category, PlTextarea } from '../index';
import { ComponentService, IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none">
<path fill="currentColor" d="M14.5564 18.2444C14.4119 18.2444 14.2675 18.1892 14.1572 18.0791C13.9367 17.8587 13.9367 17.5012 14.1572 17.2808L16.9659 14.4721C17.1864 14.2516 17.5436 14.2516 17.7641 14.4721C17.9846 14.6924 17.9846 15.05 17.7641 15.2704L14.9554 18.0791C14.8452 18.1892 14.7009 18.2444 14.5564 18.2444Z"/>
<path fill="currentColor" d="M16.4994 18.5593C16.3549 18.5593 16.2105 18.5041 16.1002 18.394C15.8797 18.1737 15.8797 17.8161 16.1002 17.5958L17.2808 16.4151C17.5013 16.1946 17.8586 16.1946 18.079 16.4151C18.2995 16.6354 18.2995 16.993 18.079 17.2133L16.8984 18.394C16.7882 18.5043 16.6437 18.5593 16.4994 18.5593Z"/>
<path fill="currentColor" d="M19.2983 0.137085H0.701479C0.388737 0.137085 0.136963 0.388859 0.136963 0.701601V19.2984C0.136963 19.6111 0.388737 19.8629 0.701479 19.8629H19.2983C19.611 19.8629 19.8628 19.6111 19.8628 19.2984V0.701601C19.8628 0.388698 19.6112 0.137085 19.2983 0.137085ZM18.7337 18.7339H1.266V1.26612H18.7337V18.7339Z"/>
<path fill="currentColor" d="M8.8902 3.57422V5.69019H8.04665L8.03052 5.63067C7.96745 5.40325 7.916 5.2339 7.87891 5.12261C7.84197 5.01938 7.8031 4.93067 7.76132 4.8597C7.71762 4.78873 7.67245 4.7289 7.62745 4.6839C7.58229 4.63874 7.53391 4.60148 7.48229 4.57406C7.43068 4.54664 7.36616 4.52728 7.28874 4.51277C7.20649 4.49825 7.09697 4.4918 6.96149 4.4918H6.67439V9.51454C6.67439 9.69341 6.67923 9.8289 6.68891 9.91777C6.69858 10.0031 6.71471 10.0708 6.73729 10.121C6.76149 10.1694 6.78891 10.2081 6.81955 10.2371C6.85181 10.2663 6.89858 10.2937 6.95987 10.3195C7.02294 10.3452 7.11149 10.3662 7.22455 10.3824L7.29391 10.3921V10.8808H4.30632V10.3937L4.37407 10.3824C4.50794 10.3599 4.616 10.3308 4.69326 10.2937C4.76262 10.2615 4.811 10.2178 4.84326 10.1615C4.87891 10.1002 4.90133 10.0195 4.91116 9.91954C4.92084 9.81148 4.92568 9.67599 4.92568 9.5147V4.49196H4.59181C4.39826 4.49196 4.25149 4.51293 4.1531 4.55648C4.05616 4.59841 3.97084 4.66615 3.89987 4.75809C3.82568 4.85164 3.76116 4.98228 3.70471 5.1468C3.64826 5.31777 3.59987 5.47906 3.56439 5.62728L3.54987 5.69019H2.70471V3.57422H8.8902Z"/>
</svg>`

export const PlTextareaConfig: IComponentsBundleItem = {
  id: 'textarea',
  name: 'Textarea',
  tag: 'pl-textarea',
  container: false,
  category: category.forms,
  svgIcon: svg,
  defaults: {
    attributes: {
      placeholder: 'Enter your input',
      label: 'Text area label',
    },
  },
  events: [],
  configurators: {
    enable: [
      {
        id: 'text:label',
        config: {
          attribute: 'label',
          label: 'Label',
          placeholder: 'Textarea label',
        },
      },
      {
        id: 'text:placeholder',
        config: {
          attribute: 'placeholder',
          label: 'Placeholder',
          placeholder: 'Placeholder text',
        },
      },
      {
        id: 'innerHtml',
        config: {
          label: 'Text area content',
        },
      },
      {
        id: 'fontSize',
      },
      {
        id: 'visibility',
      },
    ],
  },
  variables: [{ name: 'value', bind: 'innerHtml' }],
  afterCreate: (e: HTMLElement, c: ComponentService) => {
    e.addEventListener('change', (e: Event) => {
      c.SetVariable('value', (e.target as HTMLInputElement).value);
    });
  },
  setContent: (e: HTMLElement, c: ComponentService, content: string) => {
    (e as PlTextarea).setContent(content);
  },
};
