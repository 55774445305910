import { IComponentsBundleItem } from '@mf-ide/common';
import { category } from '../index';

//language=xml
const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
  <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
  <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"/>
</svg>`

export const HtmlImgConfig: IComponentsBundleItem = {
  id:"image",
  tag:"img",
  name: "Image",
  category: category.primitives,
  container: false,
  svgIcon: svg,
  defaults: {
    attributes:{
      src:"/building-city.svg"
    },
    style:{
      width:"128px",
      height:"128px"
    }
  },
  events: [],
  configurators:{
    enable: [
      {
        id: 'text:src',
        config: {
          attribute: "src",
          label: 'Image src'
        }
      },
      {
        id: 'object-fit',
        config: {
          label: "Fit"
        }
      },
      {
        id: 'select:blend-mode',
        config:{

        }
      }
    ]
  },
  variables: []
}