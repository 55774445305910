import { ComponentService, IComponentsBundleItem } from '@mf-ide/common';
import { category, PlAlert } from '../index';

//language=xml
const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
    </svg>`;

export const PlAlertConfig: IComponentsBundleItem = {
  id: 'alert',
  name: 'Alert',
  tag: 'pl-alert',
  container: false,
  category: category.advanced,
  svgIcon: svg,
  defaults: {
    attributes: {
      icon: 'bi bi-info-circle',
      title: 'Alert title',
    },
    innerHtml: 'Alert message',
  },
  events: [],
  configurators: {
    enable: [
      {
        id: 'text',
        config: {
          attribute: 'title',
          label: 'Alert title',
        },
      },
      {
        id: 'innerHtml',
        config: {
          label: 'Alert content',
        },
      },
      {
        id: 'select:variant',
        config: {
          attribute: 'variant',
          default: 'info',
          label: 'Variant',
          options: {
            info: 'Info',
            success: 'Success',
            neutral: 'Neutral',
            warning: 'Warning',
            error: 'Error',
          },
        },
      },
      {
        id: 'select:accent',
        config: {
          attribute: 'accent',
          default: 'none',
          label: 'Accent',
          options: {
            none: 'None',
            top: 'Top',
            right: 'Right',
            bottom: 'Bottom',
            left: 'Left',
          },
        },
      },
      {
        id: 'icon',
        config: {
          label: 'Alert icon',
        },
      },
      {
        id: 'boolean:closable',
        config: {
          label: 'Can be closed',
          attribute: 'closable',
        },
      },
      {
        id: 'boolean:loading',
        config: {
          attribute: 'loading',
          label: 'Loading',
        },
      },
      {
        id: 'visibility',
      },
    ],
  },
  variables: [
    { name: 'closable', bind: 'attribute', bindName: 'closable' },
    { name: 'icon', bind: 'attribute', bindName: 'icon' },
    { name: 'title', bind: 'attribute', bindName: 'title' },
    { name: 'loading', bind: 'attribute', bindName: 'loading' },
    { name: 'content', bind: 'innerHtml' },
  ],
  setContent: (e: HTMLElement, _: ComponentService, content: string) => {
    (e as PlAlert).setText(content);
  },
};
