import { defineCustomElement } from '../_decorators/element';

@defineCustomElement('pl-ripple')
export class PlRipple extends HTMLElement {
  private connected = false;

  private internalClick = (e: MouseEvent) => {
    if (!this.parentElement) return;
    this.removeAttribute('animate');

    const posX = this.parentElement.offsetLeft;
    const posY = this.parentElement.offsetTop;
    let buttonWidth = this.parentElement.offsetWidth;
    let buttonHeight = this.parentElement.offsetHeight;

    if (buttonWidth >= buttonHeight) {
      buttonHeight = buttonWidth;
    } else {
      buttonWidth = buttonHeight;
    }

    const x = e.pageX - posX - buttonWidth / 2;
    const y = e.pageY - posY - buttonHeight / 2;

    this.style.width = buttonWidth + 'px';
    this.style.height = buttonHeight + 'px';
    this.style.left = x + 'px';
    this.style.top = y + 'px';

    this.setAttribute('animate', '');
  };

  connectedCallback() {
    if (!this.parentElement) return;
    this.removeAttribute('animate');
    this.parentElement.addEventListener('click', this.internalClick);
  }

  disconnectedCallback() {
    if (!this.parentElement) return;
    this.parentElement.removeEventListener('click', this.internalClick);
  }
}
