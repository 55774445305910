import { category } from '../index';
import { IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none">
<path fill="currentColor" d="M2.75 3C2.75 2.73478 2.85536 2.48043 3.04289 2.29289C3.23043 2.10536 3.48478 2 3.75 2H16.25C16.5152 2 16.7696 2.10536 16.9571 2.29289C17.1446 2.48043 17.25 2.73478 17.25 3V5C17.25 5.26522 17.1446 5.51957 16.9571 5.70711C16.7696 5.89464 16.5152 6 16.25 6C15.9848 6 15.7304 5.89464 15.5429 5.70711C15.3554 5.51957 15.25 5.26522 15.25 5V4H11V16H12C12.2652 16 12.5196 16.1054 12.7071 16.2929C12.8946 16.4804 13 16.7348 13 17C13 17.2652 12.8946 17.5196 12.7071 17.7071C12.5196 17.8946 12.2652 18 12 18H8C7.73478 18 7.48043 17.8946 7.29289 17.7071C7.10536 17.5196 7 17.2652 7 17C7 16.7348 7.10536 16.4804 7.29289 16.2929C7.48043 16.1054 7.73478 16 8 16H9V4H4.75V5C4.75 5.26522 4.64464 5.51957 4.45711 5.70711C4.26957 5.89464 4.01522 6 3.75 6C3.48478 6 3.23043 5.89464 3.04289 5.70711C2.85536 5.51957 2.75 5.26522 2.75 5V3Z"/>
</svg>
`

export const PlTextConfig: IComponentsBundleItem = {
  id: 'text',
  name: 'Text/HTML',
  tag: 'pl-text',
  container: false,
  category: category.primitives,
  svgIcon: svg,
  defaults: {
    innerHtml: 'Hello, there!',
  },
  events: [],
  configurators: {
    enable: [
      {
        id: 'innerHtml',
        config: {
          label: 'Text content',
        },
      },
      {
        id: 'fontSize',
      },
      {
        id: 'visibility',
      },
    ],
  },
  variables: [{ name: 'text', bind: 'innerHtml' }],
};
