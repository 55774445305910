import { category } from '../index';
import { IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
        <path fill="currentColor"
              d="M17.978 4.568a2.25 2.25 0 1 0-.254.764l.004-.01c.125-.23.212-.484.25-.754M2.75 4h9.76a3.283 3.283 0 0 0 .24 1.5h-10a.75.75 0 0 1 0-1.5M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75M2.75 14a.75.75 0 0 0 0 1.5h14.5a.75.75 0 0 0 0-1.5z"/>
    </svg>
`;

export const PlNavConfig: IComponentsBundleItem = {
  id: 'nav',
  name: 'Nav',
  tag: 'pl-nav',
  container: false,
  category: category.advanced,
  svgIcon: svg,
  defaults: {},
  children:[
    {
      id:"navItem1",
      tag:"pl-nav-item",
      innerHtml:"Nav one"
    },
    {
      id:"divider1",
      tag:"pl-divider",
      attributes:{
        vertical:"true"
      }
    },
    {
      id:"navItem2",
      tag:"pl-nav-item",
      innerHtml:"Nav two"
    },
  ],
  events: [],
};
