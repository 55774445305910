import { category } from '../index';
import { IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path fill="currentColor" d="M19.9993 18.2563V19.3571C19.9993 19.7131 19.713 19.9993 19.3571 19.9993H0.642887C0.28695 19.9993 0.000732422 19.7131 0.000732422 19.3571V18.2563C0.000732422 17.9022 0.28695 17.6141 0.642887 17.6141C0.99699 17.6141 1.28504 17.9022 1.28504 18.2563V18.715H9.35784V18.2563C9.35784 17.9022 9.6459 17.6141 10 17.6141C10.3541 17.6141 10.6422 17.9022 10.6422 18.2563V18.715H18.715V18.2563C18.715 17.9022 19.003 17.6141 19.3571 17.6141C19.713 17.6141 19.9993 17.9022 19.9993 18.2563Z"/>
<path fill="currentColor" d="M0.642887 12.8971C0.28695 12.8971 0.000732422 13.1833 0.000732422 13.5392V15.8987C0.000732422 16.2528 0.28695 16.5408 0.642887 16.5408C0.99699 16.5408 1.28504 16.2528 1.28504 15.8987V13.5392C1.28504 13.1833 0.99699 12.8971 0.642887 12.8971ZM0.642887 3.45923C0.28695 3.45923 0.000732422 3.74728 0.000732422 4.10138V6.46084C0.000732422 6.81678 0.28695 7.103 0.642887 7.103C0.99699 7.103 1.28504 6.81678 1.28504 6.46084V4.10138C1.28504 3.74728 0.99699 3.45923 0.642887 3.45923Z"/>
<path fill="currentColor" d="M2.38588 9.99999C2.38588 10.3541 2.09783 10.6421 1.74372 10.6421H1.28504V11.1797C1.28504 11.534 0.99699 11.8219 0.642887 11.8219C0.28695 11.8219 0.000732422 11.534 0.000732422 11.1797V8.82026C0.000732422 8.46615 0.28695 8.1781 0.642887 8.1781C0.99699 8.1781 1.28504 8.46615 1.28504 8.82026V9.35783H1.74372C2.09783 9.35783 2.38588 9.64588 2.38588 9.99999Z"/>
<path fill="currentColor" d="M0.642155 2.38515C0.287502 2.38515 0 2.09764 0 1.74299V0.642155C0 0.287502 0.287502 0 0.642155 0H1.74299C2.09764 0 2.38515 0.287502 2.38515 0.642155C2.38515 0.996808 2.09764 1.28431 1.74299 1.28431H1.28431V1.74299C1.28431 2.09764 0.996808 2.38515 0.642155 2.38515Z"/>
<path fill="currentColor" d="M15.8987 0.000732422H13.5392C13.1833 0.000732422 12.8971 0.28695 12.8971 0.642887C12.8971 0.99699 13.1833 1.28504 13.5392 1.28504H15.8987C16.2528 1.28504 16.5408 0.99699 16.5408 0.642887C16.5408 0.28695 16.2528 0.000732422 15.8987 0.000732422ZM6.46084 0.000732422H4.10138C3.74728 0.000732422 3.45923 0.28695 3.45923 0.642887C3.45923 0.99699 3.74728 1.28504 4.10138 1.28504H6.46084C6.81678 1.28504 7.103 0.99699 7.103 0.642887C7.103 0.28695 6.81678 0.000732422 6.46084 0.000732422Z"/>
<path fill="currentColor" d="M11.8219 0.642887C11.8219 0.99699 11.534 1.28504 11.1797 1.28504H10.6421V1.74372C10.6421 2.09783 10.3541 2.38588 9.99999 2.38588C9.64588 2.38588 9.35783 2.09783 9.35783 1.74372V1.28504H8.82026C8.46615 1.28504 8.1781 0.99699 8.1781 0.642887C8.1781 0.28695 8.46615 0.000732422 8.82026 0.000732422H11.1797C11.5338 0.000732422 11.8219 0.28695 11.8219 0.642887Z"/>
<path fill="currentColor" d="M19.3579 2.38515C19.0032 2.38515 18.7157 2.09764 18.7157 1.74299V1.28431H18.257C17.9024 1.28431 17.6149 0.996808 17.6149 0.642155C17.6149 0.287502 17.9024 0 18.257 0H19.3579C19.7125 0 20 0.287502 20 0.642155V1.74299C20 2.09764 19.7125 2.38515 19.3579 2.38515Z"/>
<path fill="currentColor" d="M19.3572 3.45923C19.0031 3.45923 18.7151 3.74728 18.7151 4.10138V6.46084C18.7151 6.81678 19.0031 7.103 19.3572 7.103C19.7132 7.103 19.9994 6.81678 19.9994 6.46084V4.10138C19.9994 3.74728 19.7132 3.45923 19.3572 3.45923ZM19.3572 12.8971C19.0031 12.8971 18.7151 13.1833 18.7151 13.5392V15.8987C18.7151 16.2528 19.0031 16.5408 19.3572 16.5408C19.7132 16.5408 19.9994 16.2528 19.9994 15.8987V13.5392C19.9994 13.1833 19.7132 12.8971 19.3572 12.8971Z"/>
<path fill="currentColor" d="M19.9994 8.82026V11.1797C19.9994 11.534 19.7132 11.8219 19.3572 11.8219C19.0031 11.8219 18.7151 11.534 18.7151 11.1797V10.6421H18.2564C17.9023 10.6421 17.6143 10.3541 17.6143 9.99999C17.6143 9.64588 17.9023 9.35783 18.2564 9.35783H18.7151V8.82026C18.7151 8.46615 19.0031 8.1781 19.3572 8.1781C19.7132 8.1781 19.9994 8.46615 19.9994 8.82026Z"/>
<path fill="currentColor" d="M9.99994 12.8971C9.64584 12.8971 9.35779 13.1833 9.35779 13.5392V15.8987C9.35779 16.2528 9.64584 16.5408 9.99994 16.5408C10.354 16.5408 10.6421 16.2528 10.6421 15.8987V13.5392C10.6421 13.1833 10.354 12.8971 9.99994 12.8971ZM9.99994 3.45923C9.64584 3.45923 9.35779 3.74728 9.35779 4.10138V6.46084C9.35779 6.81678 9.64584 7.103 9.99994 7.103C10.354 7.103 10.6421 6.81678 10.6421 6.46084V4.10138C10.6421 3.74728 10.354 3.45923 9.99994 3.45923Z"/>
<path fill="currentColor" d="M6.46084 9.35791H4.10138C3.74728 9.35791 3.45923 9.64596 3.45923 10.0001C3.45923 10.3542 3.74728 10.6422 4.10138 10.6422H6.46084C6.81678 10.6422 7.103 10.3542 7.103 10.0001C7.103 9.64596 6.81678 9.35791 6.46084 9.35791ZM15.8987 9.35791H13.5392C13.1833 9.35791 12.8971 9.64596 12.8971 10.0001C12.8971 10.3542 13.1833 10.6422 13.5392 10.6422H15.8987C16.2528 10.6422 16.5408 10.3542 16.5408 10.0001C16.5408 9.64596 16.2528 9.35791 15.8987 9.35791Z"/>
<path fill="currentColor" d="M11.8219 9.99999C11.8219 10.3541 11.534 10.6421 11.1797 10.6421H10.6421V11.1797C10.6421 11.534 10.3541 11.8219 9.99999 11.8219C9.64588 11.8219 9.35783 11.534 9.35783 11.1797V10.6421H8.82026C8.46615 10.6421 8.1781 10.3541 8.1781 9.99999C8.1781 9.64588 8.46615 9.35783 8.82026 9.35783H9.35783V8.82026C9.35783 8.46615 9.64588 8.1781 9.99999 8.1781C10.3541 8.1781 10.6421 8.46615 10.6421 8.82026V9.35783H11.1797C11.5338 9.35783 11.8219 9.64588 11.8219 9.99999Z"/>
</svg>
`

export const PlBoxConfig: IComponentsBundleItem = {
  id: 'box',
  name: 'Box',
  tag: 'pl-box',
  container: true,
  category: category.primitives,
  svgIcon: svg,
  events: ['click'],
  configurators: {
    enable: [
      {
        id: 'bind',
        config: {
          types: ['object', 'array'],
        },
      },
      {
        id: 'visibility',
      },
    ],
  },
};
