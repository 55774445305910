import { PlDividerConfig } from './divider/pl-divider.config';
import { PlButtonConfig } from './button/pl-button.config';
import { PlAlertConfig } from './alert/pl-alert.config';
import { PlBoxConfig } from './box/pl-box.config';
import { PlButtonGroupConfig } from './button-group/pl-button-group.config';
import { PlCheckboxConfig } from './checkbox/pl-checkbox.config';
import { PlInputConfig } from './input/pl-input.config';
import { PlLinkConfig } from './link/pl-link.config';
import { PlRadioConfig } from './radio/pl-radio.config';
import { PlTextConfig } from './text/pl-text.config';
import { PlSelectConfig } from './select/pl-select.config';
import { PlTextareaConfig } from './textarea/pl-textarea.config';
import { PlToggleConfig } from './toggle/pl-toggle.config';
import { PlTabsConfig } from './tabs/pl-tabs.config';
import { PlSpinnerConfig } from './spinner/pl-spinner.config';
import { PlNavConfig } from './nav/pl-nav.config';
import { IComponentsBundle, PlatmaRegistry } from '@mf-ide/common';
import { PlCloseButtonConfig } from './close-button/pl-close-button.config';
import { PlArrowCardConfig } from './cards/pl-arrow-card.config';
import { PlImageCardConfig } from './cards/pl-image-card.config';
import { HtmlImgConfig } from './_html/html-img.config';

export * from './_html/html-img.config';

export * from './alert/pl-alert';
export * from "./box/pl-box"
export * from "./button/pl-button"
export * from "./button-group/pl-button-group"
export * from "./cards/pl-arrow-card"
export * from "./cards/pl-image-card"
export * from "./checkbox/pl-checkbox"
export * from "./close-button/pl-close-button"
export * from "./divider/pl-divider"
export * from "./input/pl-input"
export * from "./link/pl-link"
// Modal
export * from "./modal/pl-modal"
export * from "./modal/pl-modal-header"
export * from "./modal/pl-modal-overlay"
// Nav
export * from "./nav/pl-nav"
// export * from "./nav/pl-nav-item"
export * from "./radio/pl-radio"
export * from "./ripple/pl-ripple"
export * from "./select/pl-select"
export * from "./spinner/pl-spinner"
export * from "./tabs/pl-tabs"
export * from "./tabs/pl-tab"
export * from "./text/pl-text"
export * from "./textarea/pl-textarea"
export * from "./toggle/pl-toggle"
export * from "./tooltip/pl-tooltip"
export * from "./tree/pl-tree"

export enum category {
  advanced = "Advanced",
  buttons = "Buttons",
  cards = "Cards",
  forms = "Forms",
  primitives = "Primitives",
}

export const bundle:IComponentsBundle = {
  name: "Default",
  components: [
    // HTML tag configs
    HtmlImgConfig,

    // PL components
    PlAlertConfig,
    PlBoxConfig,
    PlButtonConfig,
    PlButtonGroupConfig,

    PlArrowCardConfig,
    PlImageCardConfig,

    PlCheckboxConfig,
    PlCloseButtonConfig,

    PlDividerConfig,

    PlInputConfig,

    PlLinkConfig,
    PlNavConfig,

    PlRadioConfig,
    PlSelectConfig,
    PlSpinnerConfig,
    PlTabsConfig,
    PlTextConfig,
    PlTextareaConfig,
    PlToggleConfig,
    // PlTreeConfig,
  ]
}

if("PLATMA_REGISTRY" in window) {
  (window["PLATMA_REGISTRY"] as PlatmaRegistry).RegisterComponentsBundle(bundle)
}

