import { PLATMA_DESIGN_MODE_ATTRIBUTE } from '../constants';
import { HtmlLayer } from './HtmlLayer';
import { AppService } from './';
import dayjs from 'dayjs';

export class ExpressionLayer extends HtmlLayer {
  public SetAttributes(attributes: { [index: string]: string } | undefined) {
    if (this.component && this.component.structure && attributes) {
      this.component.structure.attributes = attributes;
      Object.keys(attributes).forEach((attributeName) => {
        if (
          ['id', 'class', 'style', PLATMA_DESIGN_MODE_ATTRIBUTE].indexOf(
            attributeName,
          ) >= 0
        ) {
          return;
        }
        this.SetAttribute(attributeName, attributes[attributeName]);
      });
    }
  }

  public SetAttribute(name: string, value: string) {
    if (ExpressionLayer.Test(value)) {
      this.EvaluateAttribute(name, value);
    } else {
      super.SetAttribute(name, value);
    }
  }

  protected EvaluateAttributes() {
    if (!this.structure || !this.structure.attributes) return;
    Object.keys(this.structure.attributes).forEach((an) => {
      const v = this.structure.attributes?.[an];
      this.EvaluateAttribute(an, v ?? '');
    });
  }

  protected EvaluateAttribute(name: string, value: string) {
    if (
      !this.component ||
      !this.component.structure ||
      !this.component.structure.attributes
    )
      return;
    const originalValue = this.GetConfigAttribute(name, '');
    if (this.screen && ExpressionLayer.Test(originalValue)) {
      super.SetAttribute(name, this.EvaluateSingleValue(originalValue));
    }
  }

  public EvaluateContent() {
    if (
      this.component &&
      this.config &&
      this.htmlElement &&
      typeof this.structure.innerHtml === 'string'
    ) {
      let content = this.structure.innerHtml;
      if (ExpressionLayer.Test(content)) {
        content = this.EvaluateSingleValue(content);
      }
      super.SetContent(content);
    }
  }

  public EvaluateSingleValue(value: string): any {
    if (!this.screen) return;
    if (!this.component) return;
    const vars: any = AppService.GetVariables(this.screen.id);
    vars.data = this.component.data;
    vars.data_index = this.component.data_index;
    return ExpressionLayer.Eval(value, vars);
  }

  public static Test(text: string): boolean {
    const regex = /{{(.*?)}}/g;
    return regex.test(text);
  }

  public static Eval(expression: string, variables: any): any {
    variables['dayjs'] = dayjs;

    function evaluateExpression(expression: string): any {
      const regex = /{{(.*?)}}/g;
      return expression.replace(regex, (_match, expr) => {
        try {
          const r = new Function(`with(this) {return (${expr})}`).call(
            variables,
          );
          return r ?? '';
        } catch (error: any) {
          return null; //error.toString();
        }
      });
    }

    return evaluateExpression(expression);
  }

  public static EvaluateCode(code: string, variables: any): any {
    try {
      new Function(`with(this) {${code}}`).call(variables);
    } catch (error: any) {
      // return error.toString();
    }
  }
}
