import { defineCustomElement } from '../_decorators/element';

@defineCustomElement('pl-tabs')
export class PlTabs extends HTMLElement {
  connected = false;
  tabs: IPlTab[] = [];
  activeTab = 0;

  updTabs() {
    const _tabs: IPlTab[] = [];
    let ii = 0;
    this.querySelectorAll('pl-tab').forEach((e: Element) => {
      const ce = e as HTMLElement;
      if (ce.tagName.toLowerCase() === 'pl-tab') {
        const side = ce.getAttribute('side');
        const heading = ce.getAttribute('heading');
        const disabled = ce.getAttribute('disabled');
        const tab: IPlTab = {
          heading: heading ? heading : '',
          active: this.activeTab === ii,
          disabled: disabled ? disabled === 'true' : false,
          side: !side || side !== 'end' ? 'start' : 'end',
        };
        _tabs.push(tab);
        ii++;
      }
    });
    this.tabs = _tabs;
    this.updateDisplay();
  }

  updateDisplay() {
    let ii = 0;
    this.querySelectorAll('pl-tab').forEach((e: Element) => {
      const ce = e as HTMLElement;
      if (ce.tagName.toLowerCase() === 'pl-tab') {
        (ce as HTMLElement).style.display =
          this.activeTab == ii ? 'block' : 'none';
        ii++;
      }
    });
    this.querySelectorAll('pl-nav>pl-nav-item').forEach((e: Element) => {
      const ce = e as HTMLElement;
      if (ce.dataset.index !== this.activeTab.toString()) {
        ce.removeAttribute('active');
      } else {
        ce.setAttribute('active', 'true');
      }
    });
  }

  tabHead(t: IPlTab, i: number) {
    const ni = document.createElement('pl-nav-item');
    ni.setAttribute('variant', 'underline');
    if (t.active) {
      ni.setAttribute('active', 'true');
    } else {
      ni.removeAttribute('active');
    }
    if (t.disabled) {
      ni.setAttribute('disabled', 'true');
    } else {
      ni.removeAttribute('disabled');
    }
    ni.innerText = t.heading;
    ni.dataset.index = i.toString();
    return ni;
  }

  updActiveTab() {
    const at = this.getAttribute('active-tab');
    this.activeTab = at ? Number(at) : 0;
  }

  private navClick = (e: MouseEvent) => {
    const ee = e.target as HTMLElement;
    if (ee.tagName.toLowerCase() === 'pl-nav-item') {
      if (ee.getAttribute('disabled')) {
        return;
      }
      this.activeTab = Number(ee.dataset.index);
      this.updateDisplay();
    }
  };

  connectedCallback() {
    if (!this.connected) {
      this.updActiveTab();
      this.connected = true;
      this.updTabs();
      let nav = this.querySelector('pl-nav') as HTMLAnchorElement;
      if (!nav) {
        nav = document.createElement('pl-nav') as HTMLAnchorElement;
        this.prepend(nav);
        nav.addEventListener('click', this.navClick);
      } else {
        nav.innerHTML = '';
      }
      this.tabs.forEach((t, index) => {
        if (nav) {
          nav.append(this.tabHead(t, index));
        }
      });
      this.updateDisplay();
    }
  }

  disconnectedCallback() {
    const nav = this.querySelector('pl-nav') as HTMLAnchorElement;
    if (nav) {
      nav.removeEventListener('click', this.navClick);
    }
  }
}

export interface IPlTab {
  heading: string
  active: boolean
  disabled: boolean
  side: "start" | "end"
}
