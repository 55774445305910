import {AppService, IPlatmaApp, IQuery, PlApp, QueryService} from '@mf-ide/common';
import '@mf-ide/components';
import './index.scss';

declare var app: IPlatmaApp;
declare var queries: IQuery[]

QueryService.Init(queries)
AppService.InitStatic(app);
AppService.SetName("ToDo App")
const plApp = (document.getElementById("pl-app") as PlApp);
plApp.Init();
plApp.Navigate(window.location.pathname);