import { category } from '../index';
import { IComponentsBundleItem } from '@mf-ide/common';

//language=xml
const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 21" fill="none">
        <path fill="currentColor" d="M6.31456 13.4571C6.69344 13.4571 7.0568 13.3066 7.32471 13.0387C7.59262 12.7708 7.74313 12.4074 7.74313 12.0285C7.74313 11.6497 7.59262 11.2863 7.32471 11.0184C7.0568 10.7505 6.69344 10.6 6.31456 10.6C5.93568 10.6 5.57231 10.7505 5.30441 11.0184C5.0365 11.2863 4.88599 11.6497 4.88599 12.0285C4.88599 12.4074 5.0365 12.7708 5.30441 13.0387C5.57231 13.3066 5.93568 13.4571 6.31456 13.4571Z"/>
        <path fill="currentColor" d="M12.0287 12.0285C12.0287 12.4074 11.8781 12.7708 11.6102 13.0387C11.3423 13.3066 10.979 13.4571 10.6001 13.4571C10.2212 13.4571 9.85784 13.3066 9.58993 13.0387C9.32202 12.7708 9.17151 12.4074 9.17151 12.0285C9.17151 11.6497 9.32202 11.2863 9.58993 11.0184C9.85784 10.7505 10.2212 10.6 10.6001 10.6C10.979 10.6 11.3423 10.7505 11.6102 11.0184C11.8781 11.2863 12.0287 11.6497 12.0287 12.0285V12.0285Z"/>
        <path fill="currentColor" d="M14.8858 13.4571C15.2647 13.4571 15.6281 13.3066 15.896 13.0387C16.1639 12.7708 16.3144 12.4074 16.3144 12.0285C16.3144 11.6497 16.1639 11.2863 15.896 11.0184C15.6281 10.7505 15.2647 10.6 14.8858 10.6C14.507 10.6 14.1436 10.7505 13.8757 11.0184C13.6078 11.2863 13.4573 11.6497 13.4573 12.0285C13.4573 12.4074 13.6078 12.7708 13.8757 13.0387C14.1436 13.3066 14.507 13.4571 14.8858 13.4571V13.4571Z"/>
        <path fill="currentColor" d="M0.600098 4.1714C0.600098 3.2242 0.976372 2.3158 1.64614 1.64602C2.31592 0.97625 3.22432 0.599976 4.17153 0.599976H17.0287C17.9759 0.599976 18.8843 0.97625 19.5541 1.64602C20.2238 2.3158 20.6001 3.2242 20.6001 4.1714V17.0285C20.6001 17.9757 20.2238 18.8842 19.5541 19.5539C18.8843 20.2237 17.9759 20.6 17.0287 20.6H4.17153C3.22432 20.6 2.31592 20.2237 1.64614 19.5539C0.976372 18.8842 0.600098 17.9757 0.600098 17.0285V4.1714ZM4.17153 2.02855C3.60321 2.02855 3.05816 2.25431 2.6563 2.65618C2.25443 3.05804 2.02867 3.60308 2.02867 4.1714V17.0285C2.02867 17.5969 2.25443 18.1419 2.6563 18.5438C3.05816 18.9456 3.60321 19.1714 4.17153 19.1714H17.0287C17.597 19.1714 18.142 18.9456 18.5439 18.5438C18.9458 18.1419 19.1715 17.5969 19.1715 17.0285V6.31426H9.88581C9.31749 6.31426 8.77245 6.0885 8.37058 5.68663C7.96872 5.28477 7.74295 4.73973 7.74295 4.1714V2.02855H4.17153ZM19.1715 4.1714C19.1715 3.60308 18.9458 3.05804 18.5439 2.65618C18.142 2.25431 17.597 2.02855 17.0287 2.02855H9.17153V4.1714C9.17153 4.36084 9.24678 4.54253 9.38074 4.67648C9.51469 4.81043 9.69637 4.88569 9.88581 4.88569H19.1715V4.1714Z"/>
    </svg>
`;

export const PlTabsConfig: IComponentsBundleItem = {
  id: 'tabs',
  name: 'Tabs',
  tag: 'pl-tabs',
  container: false,
  category: category.advanced,
  svgIcon: svg,
  defaults: {
    attributes:{
      "active-tab":"1"
    }
  },
  events: [],
  children: [
    {
      id:"tab1",
      tag:"pl-tab",
      container: true,
      attributes:{
        heading:"Tab one"
      },
      style:{
        height:"128px"
      },
      children:[]
    },
    {
      id:"tab2",
      tag:"pl-tab",
      container: true,
      attributes:{
        heading:"Tab two"
      },
      style:{
        height:"128px"
      },
      children:[]
    }
  ],
  configurators: {
    enable: [
      {
        id: 'visibility',
      },
    ],
  },
};
