import { defineCustomElement } from '../_decorators/element';
import { attribute } from '../_decorators/attribute';
import { PlSpinner } from '../spinner/pl-spinner';
import { PlTooltip } from '../tooltip/pl-tooltip';
import { PlRipple } from '../ripple/pl-ripple';

@defineCustomElement('pl-button')
export class PlButton extends HTMLElement {
  private connected = false;

  private readonly span: HTMLElement;
  private readonly icon: HTMLElement;
  private readonly spinner: PlSpinner;

  @attribute('ripple') setRipple(v: string | null) {
    const ripple = this.querySelector('pl-ripple') as PlRipple;
    if (v && v === 'true') {
      if (!ripple) this.append(document.createElement('pl-ripple'));
    } else {
      this.removeAttribute('ripple');
      if (ripple) ripple.remove();
    }
  }

  @attribute('icon') setIcon(icon: string | null) {
    this.icon.className = icon ?? '';
    this.icon.style.display = icon ? 'flex' : 'none';
  }

  @attribute('loading') setLoading(v: string | null) {
    this.spinner.style.display =
      v && (v === 'true' || v !== 'false') ? 'flex' : 'none';
  }

  @attribute('tooltip') setTooltip(v: string | null) {
    let t = this.querySelector('pl-tooltip');
    if (v) {
      if (!t) {
        t = document.createElement('pl-tooltip') as PlTooltip;
        t.setAttribute('anchor', this.id);
        this.append(t);
      }
      t.innerHTML = v;
    } else {
      if (t) {
        t.remove();
      }
    }
  }

  public setText(text: string) {
    if (text.trim() != '') {
      this.span.innerHTML = text;
      this.span.style.display = '';
    } else {
      this.span.innerHTML = '';
      this.span.style.display = 'none';
    }
  }

  constructor() {
    super();
    this.span = document.createElement('span');
    this.icon = document.createElement('i');
    this.spinner = document.createElement('pl-spinner') as PlSpinner;
    this.spinner.setAttribute('overlay', '');
  }

  // noinspection JSUnusedGlobalSymbols
  connectedCallback() {
    const text = this.innerHTML;
    if (!this.connected) {
      this.innerHTML = '';
      this.append(this.icon);
      this.append(this.span);
      this.setText(text);
      this.append(this.spinner);
      this.setLoading(this.getAttribute('loading'));
      this.setIcon(this.getAttribute('icon'));
      this.setTooltip(this.getAttribute('tooltip'));
      this.connected = true;
    }
  }
}

Object.defineProperty(PlButton.prototype, 'innerHTML', {
  set: function (value) {
    this.span.innerHTML = value;
  },
  get: function () {
    return this.span.innerHTML;
  },
});
